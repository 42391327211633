import React from 'react';
import PropTypes from 'prop-types';
import {Container, Row, Col, Button, Form} from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import {subMonths, differenceInDays} from 'date-fns';
import {Bar} from 'react-chartjs-2';
import {CSVLink} from 'react-csv';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDownload} from '@fortawesome/free-solid-svg-icons';
import TopBar from '../../components/topBar';
import Footer from '../../components/footer';
import Breadcrumbs from '../../components/breadcrumbs';
import logManager from '../../utils/logManager';
import ApiError from '../../utils/apiError';
import authManager from '../../utils/authManager';
import {locationList, consumerByLocation, instantUrl} from '../../config';
import 'react-datepicker/dist/react-datepicker.css';

class Instantaneous extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            devices: [],
            locations: [],
            locationSelectedOption: null,
            devicesSelectedOption: null,
            selectedDate: new Date(),
            paramList: [],
            data: [],
            chartData: {},
        };

        this.getLocationsForSelectList = this.getLocationsForSelectList.bind(this);
        this.getConsumersForSelectList = this.getConsumersForSelectList.bind(this);
        this.handleLocationFilterChange = this.handleLocationFilterChange.bind(this);
        this.handleDevicesFilterChange = this.handleDevicesFilterChange.bind(this);
        this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
        this.getParamsForSelectList = this.getParamsForSelectList.bind(this);
        this.handleParamListFilterChange = this.handleParamListFilterChange.bind(this);
        this.renderChartData = this.renderChartData.bind(this);
    }

    componentDidMount() {
        this.fetchLocations()
            .then((locations) => {
                this.setState({locations});
            })
            .catch((error) => {
                this.setState({locations: []});
            });
    }

    fetchConsumersByLocation(location) {
        this.props.showLoader();
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('API-Key', authManager.getApiKey());

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        // const location = this.state.chart1.locationSelectedOption;
        return fetch(consumerByLocation + location, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((responseBody) => {
                        throw new ApiError(response.status, response.statusText, responseBody.Status);
                    });
                }
                return response.json();
            })
            .then((devices) => {
                logManager.info(devices);
                this.props.hideLoader();
                return Promise.resolve(devices);
            })
            .catch((error) => {
                this.props.hideLoader();
                logManager.error(`statusCode : ${error.code} & statusMessage : ${error.message} & apiMessage : ${error.apiMessage}`);
                this.props.apiErrorModalHandleShow(error.code, error.message, error.apiMessage);
                return Promise.reject(new Error(error.apiMessage));
            });
    }

    getLocationsForSelectList() {
        return this.state.locations.map((element) => {
            return {value: element.location, label: element.location};
        });
    }

    fetchLocations() {
        this.props.showLoader();
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('API-Key', authManager.getApiKey());

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        return fetch(locationList, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((responseBody) => {
                        throw new ApiError(response.status, response.statusText, responseBody.Status);
                    });
                }
                return response.json();
            })
            .then((locations) => {
                logManager.info(locations);
                this.props.hideLoader();
                return Promise.resolve(locations);
            })
            .catch((error) => {
                this.props.hideLoader();
                logManager.error(`statusCode : ${error.code} & statusMessage : ${error.message} & apiMessage : ${error.apiMessage}`);
                this.props.apiErrorModalHandleShow(error.code, error.message, error.apiMessage);
                return Promise.reject(new Error(error.apiMessage));
            });
    }

    getConsumersForSelectList() {
        return this.state.devices.map((element) => {
            return {value: element.device_id, label: element.consumer_id + ' : ' + element.consumer_name};
        });
    }

    handleLocationFilterChange(locationSelectedOption) {
        logManager.info(locationSelectedOption);
        if (locationSelectedOption) {
            this.setState({locationSelectedOption: locationSelectedOption});
            this.fetchConsumersByLocation(locationSelectedOption.value)
                .then((devices) => {
                    this.setState({devices});
                })
                .catch((error) => {
                    this.setState({devices: []});
                });
        } else {
            this.setState({locationSelectedOption: null});
        }
    }

    handleDevicesFilterChange(devicesSelectedOption) {
        if (devicesSelectedOption) {
            this.setState({devicesSelectedOption: devicesSelectedOption});
        } else {
            this.setState({devicesSelectedOption: null});
        }
    }

    setSelectedDate(date) {
        this.setState({selectedDate: date});
    }

    handleFilterSubmit(e) {
        e.preventDefault();
        this.setState({paramList: [], data: [], chartData: {}});
        if (!this.state.devicesSelectedOption || !this.state.devicesSelectedOption.value) {
            return false;
        }

        if (!this.state.selectedDate) {
            return false;
        }

        const deviceId = this.state.devicesSelectedOption.value;
        const noOfDays = differenceInDays(new Date(), this.state.selectedDate);
        this.fetchInstantaneousData(noOfDays, deviceId)
            .then((result) => {
                if (result.hasOwnProperty('param_list')) {
                    this.setState({paramList: result.param_list});
                }
                if (result.hasOwnProperty('meter_data')) {
                    this.setState({data: [...result.meter_data, ...result.avg_cur, ...result.avg_volt]});
                }
            })
            .catch((error) => {

            });
    }

    fetchInstantaneousData(noOfDays, deviceId) {
        this.props.showLoader();
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('API-Key', authManager.getApiKey());

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        return fetch(instantUrl + noOfDays + '|' + deviceId, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((responseBody) => {
                        throw new ApiError(response.status, response.statusText, responseBody.Status);
                    });
                }
                return response.json();
            })
            .then((data) => {
                logManager.info(data);
                this.props.hideLoader();
                return Promise.resolve(data);
            })
            .catch((error) => {
                this.props.hideLoader();
                logManager.error(`statusCode : ${error.code} & statusMessage : ${error.message} & apiMessage : ${error.apiMessage}`);
                this.props.apiErrorModalHandleShow(error.code, error.message, error.apiMessage);
                return Promise.reject(new Error(error.apiMessage));
            });
    }

    getParamsForSelectList() {
        return this.state.paramList.map((element) => {
            return {value: element.mgc_code, label: element.mgc_des};
        });
    }

    handleParamListFilterChange(paramSelectedOption) {
        this.setState({chartData: {}});
        if (paramSelectedOption && paramSelectedOption.value) {
            this.renderChartData(paramSelectedOption.value);
        }
    }

    renderChartData(mgcCode) {
        const labels = [];
        const barData = [];
        let yLabel = '';
        const fullData = [];
        this.state.data.forEach((element) => {
            if (element.mgc_code === mgcCode) {
                labels.push(element.post_date);
                barData.push(element.fmt_valu);
                fullData.push(element);
                yLabel = element.fmt_unit;
            }
        });

        const data = {
            datasets: [{
                type: 'bar',
                label: yLabel,
                data: barData,
                fill: false,
                backgroundColor: '#3A6391',
                borderColor: '#3A6391',
                hoverBackgroundColor: '#3A6391',
                hoverBorderColor: '#3A6391',
                yAxisID: 'y-axis-1',
            }],
        };

        const options = {
            responsive: true,
            maintainAspectRatio: false,
            title: {
                display: true,
                text: 'Instantaneous Visualization',
                fontSize: 16,
            },
            tooltips: {
                mode: 'label',
            },
            legend: {
                position: 'top',
                align: 'start',
            },
            elements: {
                line: {
                    fill: false,
                },
            },
            scales: {
                xAxes: [
                    {
                        display: true,
                        gridLines: {
                            display: true,
                        },

                        labels: labels,
                        scaleLabel: {
                            display: true,
                            labelString: 'Datetime (YY-MM-DD HH:MM)',
                        },
                    },
                ],
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-1',
                        gridLines: {
                            display: true,
                        },
                        labels: {
                            show: true,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: yLabel,
                        },
                    },
                ],
            },
        };

        const chartData = {
            show: true,
            data,
            options,
            fullData,
        };
        this.setState({chartData});
    }

    render() {
        return (
            <>
                <TopBar />
                <Container fluid className="p-4 bg-white">
                    <Breadcrumbs title="Instanteneous snap" />
                    <Row className="mb-2">
                        <Col md="12">
                            <Form>
                                <Form.Row className="mb-1">
                                    <Col md="3">
                                        <Select id="locationFilter"
                                            options={this.getLocationsForSelectList()}
                                            isClearable
                                            onChange={this.handleLocationFilterChange}
                                        />
                                    </Col>
                                    <Col md="3">
                                        <Select id="devicesFilter"
                                            options={this.getConsumersForSelectList()}
                                            isClearable
                                            onChange={this.handleDevicesFilterChange}
                                        />
                                    </Col>
                                    <Col md="2">
                                        <DatePicker
                                            className="form-control"
                                            selected={this.state.selectedDate}
                                            onChange={(date) => this.setSelectedDate(date)}
                                            minDate={subMonths(new Date(), 3)}
                                            maxDate={new Date()}
                                            showDisabledMonthNavigation
                                            isClearable
                                            dateFormat="dd/MM/yyyy"
                                        />
                                    </Col>
                                    <Col md="3">
                                        <Button
                                            className="float-lg-left"
                                            type="submit"
                                            onClick={this.handleFilterSubmit}
                                            disabled={(
                                                !this.state.locationSelectedOption ||
                                                !this.state.devicesSelectedOption ||
                                                !this.state.selectedDate
                                            )? true : undefined}
                                        >Display</Button>
                                    </Col>
                                </Form.Row>
                            </Form>
                        </Col>
                    </Row>
                    {(this.state.paramList.length && this.state.data.length) ?
                        <>
                            <Row className="justify-content-md-left">
                                <Col md="3">
                                    <Select id="paramListFilter"
                                        options={this.getParamsForSelectList()}
                                        onChange={this.handleParamListFilterChange}
                                    />
                                </Col>
                                <CSVLink
                                    data={this.state.chartData.fullData ? this.state.chartData.fullData : ''}
                                    filename={'instantaneous.csv'}
                                    className="btn btn-primary btn-sm float-right"
                                    target="_blank"
                                >
                                    <FontAwesomeIcon icon={faDownload} />
                                </CSVLink>
                            </Row>
                            <Row className="mb-2">
                                <Col md="12" style={{minHeight: '350px'}}>
                                    {this.state.chartData.show ?
                                        <Bar
                                            data={this.state.chartData.data}
                                            options={this.state.chartData.options}
                                        /> : ''
                                    }
                                </Col>
                            </Row>
                        </> : ''
                    }
                </Container>
                <Footer />
            </>
        );
    }
}

Instantaneous.propTypes = {
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    apiErrorModalHandleShow: PropTypes.func.isRequired,
};

export default Instantaneous;
