import React from 'react';
import {Navbar, Nav} from 'react-bootstrap';
import {isMobile} from 'react-device-detect';
import {footerText} from '../config';

export default function Footer() {
    const style = {};
    if (isMobile) {
        style.sticky = 'bottom';
    } else {
        style.fixed = 'bottom';
    }
    return (
        <Navbar {...style} variant="dark" bg="dark" className="footer-custom p-0">
            <Nav className="mr-auto p-0">
                <Navbar.Brand>{footerText}</Navbar.Brand>
            </Nav>
        </Navbar>
    );
}
