import React from 'react';
import {useForm} from 'react-hook-form';
import {Form, Col, Button} from 'react-bootstrap';
import * as yup from 'yup';
import Select from 'react-select';
import {RHFInput} from 'react-hook-form-input';
import PropTypes from 'prop-types';
import authManager from '../../../utils/authManager';
import logManager from '../../../utils/logManager';
import {deviceAdd, deviceEdit} from '../../../config';
import ApiError from '../../../utils/apiError';

const schema = yup.object().shape({
    device_id: yup.string().required('Meter Number is required'),
    manufact_name: yup.object().shape({
        value: yup.string().required('Manufacture is required'),
        label: yup.string(),
    }),
    merchant_id: yup.string(),
    nic_id: yup.string().required('NIC Card is required'),
    meter_type: yup.object().shape({
        value: yup.string().required('Meter Type is required'),
        label: yup.string(),
    }),
    installed_type: yup.object().shape({
        value: yup.string().required('Installed Type is required'),
        label: yup.string(),
    }),
    voltage_rating: yup.object().shape({
        value: yup.string().required('Voltage Rating is required'),
        label: yup.string(),
    }),
    current_rating: yup.object().shape({
        value: yup.string().required('Current Rating is required'),
        label: yup.string(),
    }),
    ct_primary: yup.object().shape({
        value: yup.string().required('CT Primary is required'),
        label: yup.string(),
    }),
    ct_secondary: yup.object().shape({
        value: yup.string().required('CT Secondary is required'),
        label: yup.string(),
    }),
    ct_ratio: yup.number(),
    pt_primary: yup.object().shape({
        value: yup.string().required('PT Primary is required'),
        label: yup.string(),
    }),
    pt_secondary: yup.object().shape({
        value: yup.string().required('PT Secondary is required'),
        label: yup.string(),
    }),
    pt_ratio: yup.number(),
    applicable_mf: yup.number(),
    position: yup.object().shape({
        value: yup.string().required('Position is required'),
        label: yup.string(),
    }),
    status_1: yup.object().shape({
        value: yup.string().required('Status is required'),
        label: yup.string(),
    }),
    connection: yup.object().shape({
        value: yup.string().required('Connection is required'),
        label: yup.string(),
    }),
});

const MeterForm = (props) => {
    // const [deviceData, setDeviceData] = useState({});
    const {register, handleSubmit, errors, setValue, getValues, reset} = useForm({
        validationSchema: schema,
        // defaultValues: props.device,
    });

    const onSubmit = async (data) => {
        logManager.info(data);
        const tempData = {};
        for (const key in data) {
            if (typeof data[key] === 'object') {
                if (data[key].value !== undefined) {
                    tempData[key] = data[key].value;
                } else {
                    tempData[key] = '';
                }
            } else {
                tempData[key] = data[key];
            }
        }
        logManager.info(tempData);
        await saveDevices(tempData, props.isAdd);
    };

    const saveDevices = (data, isAdd) => {
        props.showLoader();
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('API-Key', authManager.getApiKey());

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(data),
        };

        const url = isAdd ? deviceAdd : deviceEdit;

        return fetch(url, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((responseBody) => {
                        throw new ApiError(response.status, response.statusText, responseBody.Status);
                    });
                }
                return response.json();
            })
            .then((result) => {
                logManager.info(result);
                props.hideLoader();
                props.showSuccessfulMessage(result.Status);
            })
            .catch((error) => {
                props.hideLoader();
                logManager.error(`statusCode : ${error.code} & statusMessage : ${error.message} & apiMessage : ${error.apiMessage}`);
                props.apiErrorModalHandleShow(error.code, error.message, error.apiMessage);
            });
    };


    const getManufacturers = () => {
        return props.listOfValues.manufacturers.map((element) => {
            return {value: element.man_name, label: element.man_name};
        });
    };

    const getMeterTypes = () => {
        return props.listOfValues.meter_type.map((element) => {
            return {value: element.meter_typ, label: element.meter_typ};
        });
    };

    const getInstalledTypes = () => {
        return props.listOfValues.commissioning_type.map((element) => {
            return {value: element.comm_typ, label: element.comm_typ};
        });
    };

    const getVoltageRatings = () => {
        const results = props.listOfValues.ptr_master.reduce(function(filtered, element) {
            if (element.pt_typ === 'Secondary') {
                filtered.push({value: element.pt_rating, label: element.pt_rating});
            }
            return filtered;
        }, []);

        return results;
    };

    const getCurrentRatings = () => {
        const results = props.listOfValues.ctr_master.reduce(function(filtered, element) {
            if (element.ct_typ === 'Secondary') {
                filtered.push({value: element.ct_rating, label: element.ct_rating});
            }
            return filtered;
        }, []);

        return results;
    };

    const getCT = (type) => {
        const results = props.listOfValues.ctr_master.reduce(function(filtered, element) {
            if (element.ct_typ === type) {
                filtered.push({value: element.ct_rating, label: element.ct_rating});
            }
            return filtered;
        }, []);

        return results;
    };

    const getPT = (type) => {
        const results = props.listOfValues.ptr_master.reduce(function(filtered, element) {
            if (element.pt_typ === type) {
                filtered.push({value: element.pt_rating, label: element.pt_rating});
            }
            return filtered;
        }, []);

        return results;
    };

    const getPositions = () => {
        const results = props.listOfValues.status_master.reduce(function(filtered, element) {
            if (element.type === 'Inventory') {
                filtered.push({value: element.status, label: element.status});
            }
            return filtered;
        }, []);

        return results;
    };

    const getStatuses = () => {
        const results = props.listOfValues.status_master.reduce(function(filtered, element) {
            if (element.type === 'Meter') {
                filtered.push({value: element.status, label: element.status});
            }
            return filtered;
        }, []);

        return results;
    };

    const getConnections = () => {
        const results = props.listOfValues.status_master.reduce(function(filtered, element) {
            if (element.type === 'Installation') {
                filtered.push({value: element.status, label: element.status});
            }
            return filtered;
        }, []);

        return results;
    };

    const handleInstallTypeChange = (selectedOption) => {
        if (selectedOption) {
            if (selectedOption.value === 'Secondary') {
                setValue('ct_primary', {value: '1', label: '1'}, {shouldValidate: true});
                setValue('ct_secondary', {value: '1', label: '1'}, {shouldValidate: true});
                setValue('ct_ratio', '1', {shouldValidate: true});
                setValue('pt_primary', {value: '1', label: '1'}, {shouldValidate: true});
                setValue('pt_secondary', {value: '1', label: '1'}, {shouldValidate: true});
                setValue('pt_ratio', '1', {shouldValidate: true});
                setValue('applicable_mf', '1', {shouldValidate: true});
            }
        }
    };

    const handleCancel = () => {
        reset();
        props.handleCancel();
    };

    const setApplicableMF = () => {
        const ptRatio = getValues('pt_ratio');
        const ctRatio = getValues('ct_ratio');
        if (ptRatio && ctRatio) {
            setValue('applicable_mf', ptRatio * ctRatio, {shouldValidate: true});
        }
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md="4" controlId="device_id">
                    <Form.Label>Meter Number</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Meter Number"
                        name="device_id"
                        ref={register}
                        defaultValue={props.device.device_id}
                        readOnly={props.isAdd ? undefined : true}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.device_id && errors.device_id.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="manufact_name">
                    <Form.Label>Manufacture Name</Form.Label>
                    <RHFInput
                        as={<Select options={getManufacturers()} />}
                        name="manufact_name"
                        register={register}
                        isClearable
                        defaultValue={{
                            label: props.device.manufact_name,
                            value: props.device.manufact_name,
                        }}
                        setValue={(...selected)=>{
                            setValue(selected[0], selected[1], {shouldValidate: true});
                        }}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.manufact_name && errors.manufact_name.value && errors.manufact_name.value.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="merchant_id">
                    <Form.Label>Merchant Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Merchant Name"
                        name="merchant_id"
                        ref={register}
                        value={props.device.merchant_id || authManager.getMerchantId()}
                        readOnly
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.merchant_id && errors.merchant_id.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md="4" controlId="nic_id">
                    <Form.Label>NIC Card</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="NIC Card"
                        name="nic_id"
                        ref={register}
                        defaultValue={props.device.nic_id}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.nic_id && errors.nic_id.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="meter_type">
                    <Form.Label>Meter Type</Form.Label>
                    <RHFInput
                        as={<Select options={getMeterTypes()} />}
                        name="meter_type"
                        register={register}
                        isClearable
                        defaultValue={{
                            label: props.device.meter_type,
                            value: props.device.meter_type,
                        }}
                        setValue={(...selected) => {
                            setValue(selected[0], selected[1], {shouldValidate: true});
                        }}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.meter_type && errors.meter_type.value && errors.meter_type.value.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="installed_type">
                    <Form.Label>Installed Type</Form.Label>
                    <RHFInput
                        as={<Select options={getInstalledTypes()} />}
                        name="installed_type"
                        register={register}
                        isClearable
                        defaultValue={{
                            label: props.device.installed_type,
                            value: props.device.installed_type,
                        }}
                        setValue={(...selected) => {
                            setValue(selected[0], selected[1], {shouldValidate: true});
                            handleInstallTypeChange(selected[1]);
                        }}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.installed_type && errors.installed_type.value && errors.installed_type.value.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md="4" controlId="voltage_rating">
                    <Form.Label>Voltage Rating</Form.Label>
                    <RHFInput
                        as={<Select options={getVoltageRatings()} />}
                        name="voltage_rating"
                        register={register}
                        isClearable
                        defaultValue={{
                            label: props.device.voltage_rating,
                            value: props.device.voltage_rating,
                        }}
                        setValue={(...selected) => {
                            setValue(selected[0], selected[1], {shouldValidate: true});
                        }}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.voltage_rating && errors.voltage_rating.value && errors.voltage_rating.value.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="current_rating">
                    <Form.Label>Current Rating</Form.Label>
                    <RHFInput
                        as={<Select options={getCurrentRatings()} />}
                        name="current_rating"
                        register={register}
                        isClearable
                        defaultValue={{
                            label: props.device.current_rating,
                            value: props.device.current_rating,
                        }}
                        setValue={(...selected) => {
                            setValue(selected[0], selected[1], {shouldValidate: true});
                        }}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.current_rating && errors.current_rating.value && errors.current_rating.value.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="ct_primary">
                    <Form.Label>CT Primary</Form.Label>
                    <RHFInput
                        as={<Select options={getCT('Primary')} />}
                        name="ct_primary"
                        register={register}
                        isClearable
                        defaultValue={{
                            label: props.device.ct_primary,
                            value: props.device.ct_primary,
                        }}
                        setValue={(...selected) => {
                            setValue(selected[0], selected[1], {shouldValidate: true});
                        }}
                        onChange={(selected) => {
                            const ctSecondary = getValues('ct_secondary');
                            if (ctSecondary.value) {
                                setValue('ct_ratio', parseFloat(selected.value) / parseFloat(ctSecondary.value), {shouldValidate: true});
                                setApplicableMF();
                            }
                        }}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.ct_primary && errors.ct_primary.value && errors.ct_primary.value.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md="4" controlId="ct_secondary">
                    <Form.Label>CT Secondary</Form.Label>
                    <RHFInput
                        as={<Select options={getCT('Secondary')} />}
                        name="ct_secondary"
                        register={register}
                        isClearable
                        defaultValue={{
                            label: props.device.ct_secondary,
                            value: props.device.ct_secondary,
                        }}
                        setValue={(...selected) => {
                            setValue(selected[0], selected[1], {shouldValidate: true});
                        }}
                        onChange={(selected) => {
                            const ctPrimary = getValues('ct_primary');
                            if (ctPrimary.value) {
                                setValue('ct_ratio', parseFloat(ctPrimary.value) / parseFloat(selected.value), {shouldValidate: true});
                                setApplicableMF();
                            }
                        }}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.ct_secondary && errors.ct_secondary.value && errors.ct_secondary.value.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="ct_ratio">
                    <Form.Label>CT Ratio</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="CT Ratio"
                        name="ct_ratio"
                        ref={register}
                        readOnly
                        defaultValue={props.device.ct_ratio}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.ct_ratio && errors.ct_ratio.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="pt_primary">
                    <Form.Label>PT Primary</Form.Label>
                    <RHFInput
                        as={<Select options={getPT('Primary')} />}
                        name="pt_primary"
                        register={register}
                        isClearable
                        defaultValue={{
                            label: props.device.pt_primary,
                            value: props.device.pt_primary,
                        }}
                        setValue={(...selected) => {
                            setValue(selected[0], selected[1], {shouldValidate: true});
                        }}
                        onChange={(selected) => {
                            const ptSecondary = getValues('pt_secondary');
                            if (ptSecondary.value) {
                                setValue('pt_ratio', parseFloat(selected.value) / parseFloat(ptSecondary.value), {shouldValidate: true});
                                setApplicableMF();
                            }
                        }}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.pt_primary && errors.pt_primary.value && errors.pt_primary.value.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md="4" controlId="pt_secondary">
                    <Form.Label>PT Secondary</Form.Label>
                    <RHFInput
                        as={<Select options={getPT('Secondary')} />}
                        name="pt_secondary"
                        register={register}
                        isClearable
                        defaultValue={{
                            label: props.device.pt_secondary,
                            value: props.device.pt_secondary,
                        }}
                        setValue={(...selected) => {
                            setValue(selected[0], selected[1], {shouldValidate: true});
                        }}
                        onChange={(selected) => {
                            const ptPrimary = getValues('pt_primary');
                            if (ptPrimary.value) {
                                const ptRatio = parseFloat(ptPrimary.value) / parseFloat(selected.value);
                                setValue('pt_ratio', ptRatio, {shouldValidate: true});
                                setApplicableMF();
                            }
                        }}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.pt_secondary && errors.pt_secondary.value && errors.pt_secondary.value.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="pt_ratio">
                    <Form.Label>PT Ratio</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="PT Ratio"
                        name="pt_ratio"
                        ref={register}
                        readOnly
                        defaultValue={props.device.pt_ratio}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.pt_ratio && errors.pt_ratio.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="applicable_mf">
                    <Form.Label>Applicable MF</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Applicable MF"
                        name="applicable_mf"
                        ref={register}
                        readOnly
                        defaultValue={props.device.applicable_mf}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.applicable_mf && errors.applicable_mf.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md="4" controlId="position">
                    <Form.Label>Position</Form.Label>
                    <RHFInput
                        as={<Select options={getPositions()} />}
                        name="position"
                        register={register}
                        isClearable
                        defaultValue={{
                            label: props.device.position,
                            value: props.device.position,
                        }}
                        setValue={(...selected) => {
                            setValue(selected[0], selected[1], {shouldValidate: true});
                        }}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.position && errors.position.value && errors.position.value.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="status">
                    <Form.Label>Status</Form.Label>
                    <RHFInput
                        as={<Select options={getStatuses()} />}
                        name="status_1"
                        register={register}
                        isClearable
                        defaultValue={{
                            label: props.device.status,
                            value: props.device.status,
                        }}
                        setValue={(...selected) => {
                            setValue(selected[0], selected[1], {shouldValidate: true});
                        }}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.status_1 && errors.status_1.value && errors.status_1.value.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="connection">
                    <Form.Label>Connection</Form.Label>
                    <RHFInput
                        as={<Select options={getConnections()} />}
                        name="connection"
                        register={register}
                        isClearable
                        defaultValue={{
                            label: props.device.connection,
                            value: props.device.connection,
                        }}
                        setValue={(...selected) => {
                            setValue(selected[0], selected[1], {shouldValidate: true});
                        }}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.connection && errors.connection.value && errors.connection.value.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <Form.Row className="justify-content-md-center mb-2">
                <Form.Group as={Col} md="12">
                    <Button type="submit" className="float-right ml-1">Submit</Button>
                    <Button className="float-right" onClick={handleCancel}>Cancel</Button>
                </Form.Group>
            </Form.Row>
        </Form>
    );
};

MeterForm.propTypes = {
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    showSuccessfulMessage: PropTypes.func.isRequired,
    apiErrorModalHandleShow: PropTypes.func.isRequired,
    isAdd: PropTypes.bool.isRequired,
    device: PropTypes.object.isRequired,
    listOfValues: PropTypes.object.isRequired,
};

export default MeterForm;
