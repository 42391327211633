import React from 'react';
import PropTypes from 'prop-types';
import {Container, Row, Col, Button, Form} from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import {subMonths, subDays, differenceInDays} from 'date-fns';
import BootstrapTable from 'react-bootstrap-table-next';
import {CSVLink} from 'react-csv';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDownload} from '@fortawesome/free-solid-svg-icons';
import TopBar from '../../components/topBar';
import Footer from '../../components/footer';
import Breadcrumbs from '../../components/breadcrumbs';
import logManager from '../../utils/logManager';
import ApiError from '../../utils/apiError';
import authManager from '../../utils/authManager';
import {locationList, consumerByLocation, eventsUrl} from '../../config';
import 'react-datepicker/dist/react-datepicker.css';

class Events extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            devices: [],
            locations: [],
            locationSelectedOption: null,
            devicesSelectedOption: null,
            selectedDate: subDays(new Date(), 1),
            data: [],
            totalOutage: null,
        };

        this.getLocationsForSelectList = this.getLocationsForSelectList.bind(this);
        this.getConsumersForSelectList = this.getConsumersForSelectList.bind(this);
        this.handleLocationFilterChange = this.handleLocationFilterChange.bind(this);
        this.handleDevicesFilterChange = this.handleDevicesFilterChange.bind(this);
        this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
    }

    componentDidMount() {
        this.fetchLocations()
            .then((locations) => {
                this.setState({locations});
            })
            .catch((error) => {
                this.setState({locations: []});
            });
    }

    fetchConsumersByLocation(location) {
        this.props.showLoader();
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('API-Key', authManager.getApiKey());

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        // const location = this.state.chart1.locationSelectedOption;
        return fetch(consumerByLocation + location, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((responseBody) => {
                        throw new ApiError(response.status, response.statusText, responseBody.Status);
                    });
                }
                return response.json();
            })
            .then((devices) => {
                logManager.info(devices);
                this.props.hideLoader();
                return Promise.resolve(devices);
            })
            .catch((error) => {
                this.props.hideLoader();
                logManager.error(`statusCode : ${error.code} & statusMessage : ${error.message} & apiMessage : ${error.apiMessage}`);
                this.props.apiErrorModalHandleShow(error.code, error.message, error.apiMessage);
                return Promise.reject(new Error(error.apiMessage));
            });
    }

    getLocationsForSelectList() {
        return this.state.locations.map((element) => {
            return {value: element.location, label: element.location};
        });
    }

    fetchLocations() {
        this.props.showLoader();
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('API-Key', authManager.getApiKey());

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        return fetch(locationList, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((responseBody) => {
                        throw new ApiError(response.status, response.statusText, responseBody.Status);
                    });
                }
                return response.json();
            })
            .then((locations) => {
                logManager.info(locations);
                this.props.hideLoader();
                return Promise.resolve(locations);
            })
            .catch((error) => {
                this.props.hideLoader();
                logManager.error(`statusCode : ${error.code} & statusMessage : ${error.message} & apiMessage : ${error.apiMessage}`);
                this.props.apiErrorModalHandleShow(error.code, error.message, error.apiMessage);
                return Promise.reject(new Error(error.apiMessage));
            });
    }

    getConsumersForSelectList() {
        return this.state.devices.map((element) => {
            return {value: element.device_id, label: element.consumer_id + ' : ' + element.consumer_name};
        });
    }

    handleLocationFilterChange(locationSelectedOption) {
        logManager.info(locationSelectedOption);
        if (locationSelectedOption) {
            this.setState({locationSelectedOption: locationSelectedOption});
            this.fetchConsumersByLocation(locationSelectedOption.value)
                .then((devices) => {
                    this.setState({devices});
                })
                .catch((error) => {
                    this.setState({devices: []});
                });
        } else {
            this.setState({locationSelectedOption: null});
        }
    }

    handleDevicesFilterChange(devicesSelectedOption) {
        if (devicesSelectedOption) {
            this.setState({devicesSelectedOption: devicesSelectedOption});
        } else {
            this.setState({devicesSelectedOption: null});
        }
    }

    setSelectedDate(date) {
        this.setState({selectedDate: date});
    }

    handleFilterSubmit(e) {
        e.preventDefault();
        if (!this.state.devicesSelectedOption || !this.state.devicesSelectedOption.value) {
            return false;
        }

        if (!this.state.selectedDate) {
            return false;
        }

        const deviceId = this.state.devicesSelectedOption.value;
        const noOfDays = differenceInDays(new Date(), this.state.selectedDate);
        this.fetchEventData(noOfDays, deviceId)
            .then((result) => {
                if (Array.isArray(result) && result.length) {
                    const totalOutage = result.pop().outage;
                    this.setState({data: result, totalOutage});
                }
            })
            .catch((error) => {

            });
    }

    fetchEventData(noOfDays, deviceId) {
        this.props.showLoader();
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('API-Key', authManager.getApiKey());

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        return fetch(eventsUrl + noOfDays + '|' + deviceId, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((responseBody) => {
                        throw new ApiError(response.status, response.statusText, responseBody.Status);
                    });
                }
                return response.json();
            })
            .then((data) => {
                logManager.info(data);
                this.props.hideLoader();
                return Promise.resolve(data);
            })
            .catch((error) => {
                this.props.hideLoader();
                logManager.error(`statusCode : ${error.code} & statusMessage : ${error.message} & apiMessage : ${error.apiMessage}`);
                this.props.apiErrorModalHandleShow(error.code, error.message, error.apiMessage);
                return Promise.reject(new Error(error.apiMessage));
            });
    }

    getEventTableColumns() {
        const columns = [
            {
                dataField: 'slno',
                text: 'Serial No',
            },
            {
                dataField: 'post_date',
                text: 'Post Date',
            },
            {
                dataField: 'occur_time',
                text: 'Occured Time',
            },
            {
                dataField: 'restore_time',
                text: 'Restored Time',
            },
            {
                dataField: 'diff',
                text: 'Duration (hh:mm:ss)',
            },
        ];
        return columns;
    }

    render() {
        return (
            <>
                <TopBar />
                <Container fluid className="p-4 bg-white">
                    <Breadcrumbs title="Events" />
                    <Row className="mb-2">
                        <Col md="12">
                            <Form>
                                <Form.Row className="mb-1">
                                    <Col md="3">
                                        <Select id="locationFilter"
                                            options={this.getLocationsForSelectList()}
                                            isClearable
                                            onChange={this.handleLocationFilterChange}
                                        />
                                    </Col>
                                    <Col md="3">
                                        <Select id="devicesFilter"
                                            options={this.getConsumersForSelectList()}
                                            isClearable
                                            onChange={this.handleDevicesFilterChange}
                                        />
                                    </Col>
                                    <Col md="2">
                                        <DatePicker
                                            className="form-control"
                                            selected={this.state.selectedDate}
                                            onChange={(date) => this.setSelectedDate(date)}
                                            minDate={subMonths(new Date(), 3)}
                                            maxDate={subDays(new Date(), 1)}
                                            showDisabledMonthNavigation
                                            isClearable
                                            dateFormat="dd/MM/yyyy"
                                        />
                                    </Col>
                                    <Col md="3">
                                        <Button
                                            className="float-lg-left"
                                            type="submit"
                                            onClick={this.handleFilterSubmit}
                                            disabled={(
                                                !this.state.locationSelectedOption ||
                                                !this.state.devicesSelectedOption ||
                                                !this.state.selectedDate
                                            ) ? true : undefined}
                                        >Display</Button>
                                    </Col>
                                </Form.Row>
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        {this.state.data.length ?
                            <>
                                <Col md="12">
                                    <CSVLink
                                        data={this.state.data ? this.state.data : ''}
                                        filename={'events.csv'}
                                        className="btn btn-primary btn-sm float-right"
                                        target="_blank"
                                    >
                                        <FontAwesomeIcon icon={faDownload} />
                                    </CSVLink>
                                </Col>
                                <Col md="12">
                                    <BootstrapTable
                                        keyField='slno'
                                        data={this.state.data}
                                        columns={this.getEventTableColumns()}
                                        bootstrap4
                                        striped
                                        hover
                                        condensed
                                        classes="bootstrap-striped-table2"
                                    />
                                </Col>
                                <Col md="12">
                                    <b className="float-right">Total Outage Time {this.state.totalOutage}</b>
                                </Col>
                            </> : ''
                        }
                    </Row>
                </Container>
                <Footer />
            </>
        );
    }
}

Events.propTypes = {
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    apiErrorModalHandleShow: PropTypes.func.isRequired,
};

export default Events;
