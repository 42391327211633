import logManager from "./logManager";
import ApiError from "./apiError";

export default function ApiFetch(props,url,requestOptions){
    return fetch(url, requestOptions)
    .then(async (response) => {
        if (!response.ok) {
          return response.json().then((responseBody) => {
            throw new ApiError(
              response.status,
              response.statusText,
              responseBody.Status
            );
          });
        }
        return await response.json();
      })
      .then((data)=>{
        logManager.info(data);
        props.hideLoader();
        return Promise.resolve(data);
      })
      .catch((error) => {
        props.hideLoader();
        logManager.error(
          `statusCode : ${error.code} & statusMessage : ${error.message} & apiMessage : ${error.apiMessage}`
        );
        props.apiErrorModalHandleShow(
          error.code,
          error.message,
          error.apiMessage
        );
        return Promise.reject(new Error(error.apiMessage));
      });
}