import React, { useEffect, useState } from "react";
import TopBar from "../../components/topBar";
import Footer from "../../components/footer";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import Breadcrumbs from "../../components/breadcrumbs";
import DatePicker from "react-datepicker";
import { subMonths, differenceInDays } from "date-fns";
import Select from "react-select";
import ReadingReportTable from "../../components/ReadingReportTable";
import authManager from "../../utils/authManager";
import {
  consumerByLocation,
  locationList,
  lovApiUrl,
  readingReportsUrl,
} from "../../config";
import logManager from "../../utils/logManager";
import ApiFetch from "../../utils/apiFetch";

export default function Reading(props) {
  const [state, setState] = useState({
    selectedToDate: null,
    selectedFromDate: null,
    locationSelectedOption: null,
    devicesSelectedOption: null,
    paramListSelectedOption: null,
    isClearable: true,
    deviceList: [],
    locationListData: [],
    paramList: [
      "3-phase Power Factor",
      "Apparent (while active export) Energy Register",
      "Apparent while active import Energy Register",
      "Avg Phase current",
      "Avg Phase Voltage",
      "Current Total Active Import Energy Register",
      "Frequency",
      "Instantaneous Apparent Power",
      "KW import UMD",
      "Neutral current",
      "Signed active power",
      "Total Active Export Energy Register",
      "Total Apparent Import current MD"
    ],
    uniqeProfileName: null,
    data: null,
    meterTypeSelectedOption:null,
    meterTypeList:null,
   
  });

  //---------------------First Input---------------------------------------//
  const fetchLocations = ()=> {
    props.showLoader();
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("API-Key", authManager.getApiKey());

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    return ApiFetch(props, locationList, requestOptions);
  }
  
 


  useEffect(() => {
    props.showLoader();
    fetchLocations().then((locationListData) => {
        data(locationListData);
      })
      .catch((err) =>setState({ ...state, locationListData: [] }));

    
  }, []);


  const getLocationsForSelectList = () => {
    return state.locationListData.map((element) => {
      return { value: element.location, label: element.location };
    });
  };

  
  //-----------------------------Fourth Input --------------------------------//
  const fetchLOV = ()=>{
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('API-Key', authManager.getApiKey());

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
    };

    return  ApiFetch(props, lovApiUrl, requestOptions)
  };

  const data=(locationListData)=>{
    fetchLOV().then( (meterTypeList) => {
      props.hideLoader();
     
      setState({...state,locationListData:locationListData,meterTypeList:meterTypeList.meter_data.meter_type});
    })
    .catch((err) =>{
      props.hideLoader();
   
      setState({ ...state, meterTypeList: [] })
    });
  }
  
  const getListofMeterType = () => {
    return state.meterTypeList
      ? state.meterTypeList.map((element) => {
          return { value: element.meter_typ, label: element.meter_typ, key:element.id };
        })
      : [];
  };

  const handleMeterTypeFilterChange = (meterTypeSelectedOption) => {
    if (meterTypeSelectedOption) {
      setState({ ...state, meterTypeSelectedOption: meterTypeSelectedOption });
    } else {
      setState({ ...state, meterTypeSelectedOption: null });
    }
  };
  //-----------------------------------------------------------//

  function fetchConsumersByLocation(selectLocation) {
    props.showLoader();
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("API-Key", authManager.getApiKey());

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    return ApiFetch(props, consumerByLocation + selectLocation, requestOptions);
  }

  const handleLocationFilterChange = (locationSelectedOption) => {
    // console.log(locationSelectedOption);
    logManager.info(locationSelectedOption);
    if (locationSelectedOption) {
      setState({
        ...state,
        locationSelectedOption: locationSelectedOption.value,
      });
      fetchConsumersByLocation(locationSelectedOption.value)
        .then((devices) => {
          setState({
            ...state,
            deviceList: devices,
            locationSelectedOption: locationSelectedOption.value,
          });
        })
        .catch((error) => {
          setState({ ...state, deviceList: [] });
        });
    } else {
      setState({ ...state, locationSelectedOption: null });
    }
  };

  //---------------------Second Input---------------------------------------//
  const getConsumersForSelectList = () => {
    return state.deviceList.map((element) => {
      return {
        value: element.device_id,
        label: element.consumer_id + " : " + element.consumer_name,
      };
    });
  };

  const handleDevicesFilterChange = (devicesSelectedOption) => {
    // console.log(devicesSelectedOption)
    logManager.info(devicesSelectedOption);
    if (devicesSelectedOption) {
      setState({
        ...state,
        devicesSelectedOption: devicesSelectedOption.value,
      });
    } else {
      setState({ ...state, devicesSelectedOption: null });
    }
  };

  

  //---------------------Third Input---------------------------------------//
  function fetchData(
    locationSelectedOption,
    devicesSelectedOption,
    paramListSelectedOption,
    startDateNo,
    endDateNo,
    meterTypeSelectedOption
  ) {
    props.showLoader();
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("API-Key", authManager.getApiKey());

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    let urlString=locationSelectedOption+"|";
    if (!devicesSelectedOption)
    {
      urlString =urlString+"|";
    }else{
      urlString =urlString+devicesSelectedOption+"|";
    }

    if (!paramListSelectedOption)
    {
      urlString =urlString+"|";
    }else{
      urlString =urlString+paramListSelectedOption+"|";
    }

    urlString =urlString+startDateNo+"|"+endDateNo;

    if(!meterTypeSelectedOption){
      urlString =urlString+"|"
    }else{
      urlString =urlString +"|"+meterTypeSelectedOption
    }
    urlString=readingReportsUrl+urlString;
    console.log(urlString)
    return ApiFetch(props, urlString, requestOptions);
  }

  const getParamsForSelectList = () => {
    return state.paramList
      ? state.paramList.map((element) => {
          return { value: element, label: element };
        })
      : [];
  };

  const handleParamListFilterChange = (paramListSelectedOption) => {
    if (paramListSelectedOption) {
      setState({ ...state, paramListSelectedOption: paramListSelectedOption });

    } else {
      setState({ ...state, paramListSelectedOption: null });
    }
  };

  //-----------------------------Handle Submit Button-------------------------//
  const handleFilterSubmit = (e) => {
    e.preventDefault();
    const locationSelectedOption =state.locationSelectedOption;
    const devicesSelectedOption = state.devicesSelectedOption;
    const paramListSelectedOption = state.paramListSelectedOption===null ? state.paramListSelectedOption=null : state.paramListSelectedOption.value;
    const meterTypeSelectedOption = state.meterTypeSelectedOption===null ? state.meterTypeSelectedOption=null : state.meterTypeSelectedOption.value;
    const startDateNo =
      differenceInDays(new Date(), state.selectedFromDate);
    const endDateNo = differenceInDays(new Date(), state.selectedToDate);
    fetchData(
      locationSelectedOption,
      devicesSelectedOption,
      paramListSelectedOption,
      startDateNo,
      endDateNo,
      meterTypeSelectedOption
    )
      .then((data) => {
        props.hideLoader();
        setState({ ...state, data: data });
      })
      .catch((error) => {
        props.hideLoader();
        setState({ ...state, data: null });
      });
  };

  console.log(state.locationListData,state.meterTypeList)

  return (
    <>
      <TopBar />
      <Container fluid className="p-4 bg-white ">
        <Breadcrumbs title=" Reading Report" />
        <Row className="mb-2">
          <Col md="12" style={{ zIndex: 101 }}>
            <Form onSubmit={(e) => handleFilterSubmit(e)}>
              <Form.Row className="mb-1  ">
                <Col md="2">
                  <Select
                    id="locationFilter"
                    options={getLocationsForSelectList()}
                    isClearable
                    onChange={(e) => {
                      handleLocationFilterChange(e);
                    }}
                  />
                </Col>
                <Col md="2">
                  <Select
                    id="devicesFilter"
                    options={getConsumersForSelectList()}
                    isClearable
                    onChange={(e) => handleDevicesFilterChange(e)}
                  />
                </Col>
                <Col md="2">
                  <Select
                    id="paramListFilter"
                    isClearable
                    options={getParamsForSelectList()}
                    onChange={(e) => handleParamListFilterChange(e)}
                  />
                </Col>

                <Col md="1">
                  {/* form DatePicker */}
                  <DatePicker
                    className="form-control"
                    placeholderText="From Date"
                    selected={state.selectedFromDate}
                    onChange={(date) =>
                      setState({
                        ...state,
                        selectedFromDate: date,
                        selectedToDate: null,
                      })
                    }
                    minDate={subMonths(new Date(), 3)}
                    maxDate={new Date()}
                    showDisabledMonthNavigation
                    isClearable
                    dateFormat="dd/MM/yyyy"
                  />
                </Col>
                <Col md="1">
                  {/* to DatePicker */}
                  <DatePicker
                    className="form-control"
                    placeholderText="To Date"
                    selected={state.selectedToDate}
                    onChange={(date) =>
                      setState({ ...state, selectedToDate: date })
                    }
                    minDate={state.selectedFromDate}
                    maxDate={new Date()}
                    showDisabledMonthNavigation
                    isClearable
                    dateFormat="dd/MM/yyyy"
                  />
                </Col>
                <Col md="2">
                  <Select
                    id="meterType"
                    isClearable
                    options={getListofMeterType()}
                    onChange={(e) => handleMeterTypeFilterChange(e)}
                  />
                </Col>
                <Col md="3">
                  <Button
                    className="float-lg-left"
                    type="submit"
                    disabled={
                      state.locationSelectedOption &&
                      state.devicesSelectedOption &&
                      state.selectedFromDate &&
                      state.selectedToDate !== null
                        ? false
                        : true
                    }
                  >
                    Display
                  </Button>
                </Col>
              </Form.Row>
            </Form>
          </Col>
        </Row>
        {state.data ? (
          // state.locationSelectedOption && state.devicesSelectedOption && state.paramListSelectedOption && state.selectedFromDate && state.selectedToDate === null ? <></> :
          <Col className=" mb-4">
            <ReadingReportTable data={state.data} />
          </Col>
        ) : (<></>)}
      </Container>
      <Footer />
    </>
  );
}
