import React from 'react';
import {Modal, Button} from 'react-bootstrap';
import PropTypes from 'prop-types';

const ApiErrorModal = (props) => {
    return (
        <>
            <Modal show={props.error.apiErrorModalShow} onHide={props.error.apiErrorModalHandleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{`${props.error.apiError.errorCode} ${props.error.apiError.errorMessage}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{props.error.apiError.apiErrorMessage}</Modal.Body>
                <Modal.Footer>
                    { props.error.apiErrorModalShowLogin ?
                        <Button variant="secondary" onClick={props.error.apiErrorModalHandleRedirectToLogin}>
                            Login
                        </Button> :
                        <Button variant="secondary" onClick={props.error.apiErrorModalHandleClose}>
                            Close
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    );
};

ApiErrorModal.propTypes = {
    error: PropTypes.shape({
        apiErrorModalShow: PropTypes.bool.isRequired,
        apiErrorModalShowLogin: PropTypes.bool.isRequired,
        apiErrorModalHandleClose: PropTypes.func.isRequired,
        apiErrorModalHandleRedirectToLogin: PropTypes.func.isRequired,
        apiError: PropTypes.shape({
            errorCode: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
            errorMessage: PropTypes.string.isRequired,
            apiErrorMessage: PropTypes.string,
        }),
    }),
};

export default ApiErrorModal;
