import React from 'react';
import PropTypes from 'prop-types';
import {Container, Row, Col, Button, Form} from 'react-bootstrap';
import Select from 'react-select';
import BootstrapTable from 'react-bootstrap-table-next';
import {CSVLink} from 'react-csv';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDownload} from '@fortawesome/free-solid-svg-icons';
import TopBar from '../../components/topBar';
import Footer from '../../components/footer';
import Breadcrumbs from '../../components/breadcrumbs';
import logManager from '../../utils/logManager';
import ApiError from '../../utils/apiError';
import authManager from '../../utils/authManager';
import {locationList, consumerByLocation, lovApiUrl, pollDeviceUrl, checkPollStatusUrl} from '../../config';

class Polling extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            devices: [],
            locations: [],
            locationSelectedOption: null,
            devicesSelectedOption: null,
            pollDataSelectedOption: null,
            listOfValues: {},
            pollTransactionId: null,
            data: [],
            processedAt: null,
            elapseTime: null,
        };

        this.getLocationsForSelectList = this.getLocationsForSelectList.bind(this);
        this.getConsumersForSelectList = this.getConsumersForSelectList.bind(this);
        this.getPollDataForSelectList = this.getPollDataForSelectList.bind(this);
        this.handleLocationFilterChange = this.handleLocationFilterChange.bind(this);
        this.handleDevicesFilterChange = this.handleDevicesFilterChange.bind(this);
        this.handlePollDataFilterChange = this.handlePollDataFilterChange.bind(this);
        this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
        this.renderPollStatus = this.renderPollStatus.bind(this);

        this.pollStatusFetchTimer = null;
    }

    componentDidMount() {
        this.fetchLocations()
            .then((locations) => {
                this.setState({locations});
            })
            .catch((error) => {
                this.setState({locations: []});
            });

        this.fetchLOV()
            .then((listOfValues) => {
                this.setState({listOfValues});
            });
    }

    fetchConsumersByLocation(location) {
        this.props.showLoader();
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('API-Key', authManager.getApiKey());

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        // const location = this.state.chart1.locationSelectedOption;
        return fetch(consumerByLocation + location, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((responseBody) => {
                        throw new ApiError(response.status, response.statusText, responseBody.Status);
                    });
                }
                return response.json();
            })
            .then((devices) => {
                logManager.info(devices);
                this.props.hideLoader();
                return Promise.resolve(devices);
            })
            .catch((error) => {
                this.props.hideLoader();
                logManager.error(`statusCode : ${error.code} & statusMessage : ${error.message} & apiMessage : ${error.apiMessage}`);
                this.props.apiErrorModalHandleShow(error.code, error.message, error.apiMessage);
                return Promise.reject(new Error(error.apiMessage));
            });
    }

    getLocationsForSelectList() {
        return this.state.locations.map((element) => {
            return {value: element.location, label: element.location};
        });
    }

    fetchLocations() {
        this.props.showLoader();
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('API-Key', authManager.getApiKey());

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        return fetch(locationList, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((responseBody) => {
                        throw new ApiError(response.status, response.statusText, responseBody.Status);
                    });
                }
                return response.json();
            })
            .then((locations) => {
                logManager.info(locations);
                this.props.hideLoader();
                return Promise.resolve(locations);
            })
            .catch((error) => {
                this.props.hideLoader();
                logManager.error(`statusCode : ${error.code} & statusMessage : ${error.message} & apiMessage : ${error.apiMessage}`);
                this.props.apiErrorModalHandleShow(error.code, error.message, error.apiMessage);
                return Promise.reject(new Error(error.apiMessage));
            });
    }

    fetchLOV() {
        // this.props.showLoader();
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('API-Key', authManager.getApiKey());

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        return fetch(lovApiUrl, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((responseBody) => {
                        throw new ApiError(response.status, response.statusText, responseBody.Status);
                    });
                }
                return response.json();
            })
            .then((listOfValues) => {
                logManager.info(listOfValues);
                // this.props.hideLoader();
                return Promise.resolve(listOfValues.meter_data);
            })
            .catch((error) => {
                // this.props.hideLoader();
                logManager.error(`statusCode : ${error.code} & statusMessage : ${error.message} & apiMessage : ${error.apiMessage}`);
                this.props.apiErrorModalHandleShow(error.code, error.message, error.apiMessage);
                return Promise.resolve({});
            });
    }

    getConsumersForSelectList() {
        return this.state.devices.map((element) => {
            return {value: element.device_id, label: element.consumer_id + ' : ' + element.consumer_name};
        });
    }

    getPollDataForSelectList() {
        if (!this.state.listOfValues || !this.state.listOfValues.poll_data) {
            return [];
        }
        return this.state.listOfValues.poll_data.map((element) => {
            return {value: element.id, label: element.unit};
        });
    }

    handleLocationFilterChange(locationSelectedOption) {
        logManager.info(locationSelectedOption);
        if (locationSelectedOption) {
            this.setState({locationSelectedOption: locationSelectedOption});
            this.fetchConsumersByLocation(locationSelectedOption.value)
                .then((devices) => {
                    this.setState({devices});
                })
                .catch((error) => {
                    this.setState({devices: []});
                });
        } else {
            this.setState({locationSelectedOption: null});
        }
    }

    handleDevicesFilterChange(devicesSelectedOption) {
        if (devicesSelectedOption) {
            this.setState({devicesSelectedOption: devicesSelectedOption});
        } else {
            this.setState({devicesSelectedOption: null});
        }
    }

    handlePollDataFilterChange(pollDataSelectedOption) {
        if (pollDataSelectedOption) {
            this.setState({pollDataSelectedOption: pollDataSelectedOption});
        } else {
            this.setState({pollDataSelectedOption: null});
        }
    }

    handleFilterSubmit(e) {
        e.preventDefault();
        this.setState({data: [], processedAt: null, elapseTime: null});
        if (!this.state.locationSelectedOption ||
            !this.state.devicesSelectedOption ||
            !this.state.pollDataSelectedOption
        ) {
            return false;
        }

        const deviceId = this.state.devicesSelectedOption.value;
        const obs = {};
        obs.code = this.state.pollDataSelectedOption.value;
        obs.description = this.state.pollDataSelectedOption.label;
        this.props.showLoader();
        this.fetchPollTransaction(deviceId, obs)
            .then((result) => {
                const transactionId = result.tran_id || result.Status.match(/\d+$/).pop();
                logManager.info(transactionId);
                if (transactionId) {
                    this.setState({pollTransactionId: transactionId});
                    this.pollStatusFetchTimer = setInterval(this.renderPollStatus, 5000);
                }
            })
            .catch((error) => {
                this.props.hideLoader();
            });
    }

    fetchPollTransaction(deviceId, obs) {
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('API-Key', authManager.getApiKey());

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                device_id: deviceId,
                obis_code: obs.code,
                obis_des: obs.description,
            }),
        };

        return fetch(pollDeviceUrl, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((responseBody) => {
                        throw new ApiError(response.status, response.statusText, responseBody.Status);
                    });
                }
                return response.json();
            })
            .then((result) => {
                logManager.info(result);
                // this.props.hideLoader();
                return Promise.resolve(result);
            })
            .catch((error) => {
                // this.props.hideLoader();
                logManager.error(`statusCode : ${error.code} & statusMessage : ${error.message} & apiMessage : ${error.apiMessage}`);
                this.props.apiErrorModalHandleShow(error.code, error.message, error.apiMessage);
                return Promise.reject(error);
            });
    }

    fetchPollStatus(transactionId) {
        // this.props.showLoader();
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('API-Key', authManager.getApiKey());

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        return fetch(checkPollStatusUrl + transactionId, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((responseBody) => {
                        throw new ApiError(response.status, response.statusText, responseBody.Status);
                    });
                }
                return response.json();
            })
            .then((result) => {
                logManager.info(result);
                // this.props.hideLoader();
                return Promise.resolve(result);
            })
            .catch((error) => {
                // this.props.hideLoader();
                logManager.error(`statusCode : ${error.code} & statusMessage : ${error.message} & apiMessage : ${error.apiMessage}`);
                this.props.apiErrorModalHandleShow(error.code, error.message, error.apiMessage);
                return Promise.reject(error);
            });
    }

    renderPollStatus() {
        if (this.state.pollTransactionId) {
            this.fetchPollStatus(this.state.pollTransactionId)
                .then((result) => {
                    if (result.hasOwnProperty('Status')) {
                        if (result.Status.toLowerCase().includes('in progress')) {
                            return false;
                        }
                    }

                    if (result.hasOwnProperty('Status') && result.Status === 'OK') {
                        const data = Object.entries(result.registers).map(([key, value]) => {
                            return {'slno': key, ...value};
                        });
                        const processedAt = result.timestamp;
                        const elapseTime = result.elapse_time;
                        this.setState({data, processedAt, elapseTime});
                    } else {
                        this.props.apiErrorModalHandleShow(
                            200,
                            'Error', (result.Status || result.status) + '. Elapse Time ' + result.elapse_time,
                        );
                    }

                    clearTimeout(this.pollStatusFetchTimer);
                    this.props.hideLoader();
                })
                .catch((error) => {
                    clearTimeout(this.pollStatusFetchTimer);
                    this.props.hideLoader();
                });
        }
    }

    getPollTableColumns() {
        const columns = [
            {
                dataField: 'slno',
                text: 'Serial No',
            },
            {
                dataField: 'obis_des',
                text: 'Obis Description',
            },
            {
                dataField: 'obis_code',
                text: 'Obis Code',
            },
            {
                dataField: 'fmt_valu',
                text: 'Value',
            },
        ];
        return columns;
    }

    render() {
        return (
            <>
                <TopBar />
                <Container fluid className="p-4 bg-white">
                    <Breadcrumbs title="Polling" />
                    <Row className="mb-2">
                        <Col md="12">
                            <Form>
                                <Form.Row className="mb-1">
                                    <Col md="3">
                                        <Select id="locationFilter"
                                            options={this.getLocationsForSelectList()}
                                            isClearable
                                            onChange={this.handleLocationFilterChange}
                                        />
                                    </Col>
                                    <Col md="3">
                                        <Select id="devicesFilter"
                                            options={this.getConsumersForSelectList()}
                                            isClearable
                                            onChange={this.handleDevicesFilterChange}
                                        />
                                    </Col>
                                    <Col md="3">
                                        <Select id="pollDataFilter"
                                            options={this.getPollDataForSelectList()}
                                            isClearable
                                            onChange={this.handlePollDataFilterChange}
                                        />
                                    </Col>
                                    <Col md="3">
                                        <Button className="float-lg-left" type="submit" onClick={this.handleFilterSubmit}>Poll</Button>
                                    </Col>
                                </Form.Row>
                            </Form>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        {this.state.data.length ?
                            <>
                                <Col md="12">
                                    <CSVLink
                                        data={this.state.data ? this.state.data : ''}
                                        filename={'polling.csv'}
                                        className="btn btn-primary btn-sm float-right"
                                        target="_blank"
                                    >
                                        <FontAwesomeIcon icon={faDownload} />
                                    </CSVLink>
                                </Col>
                                <Col md="12">
                                    <BootstrapTable
                                        keyField='slno'
                                        data={this.state.data}
                                        columns={this.getPollTableColumns()}
                                        bootstrap4
                                        striped
                                        hover
                                        condensed
                                        classes="bootstrap-striped-table2"
                                    />
                                    <b className="float-right">Elapse Time {this.state.elapseTime}</b>
                                    <b className="float-left">Processed Time {this.state.processedAt}</b>
                                </Col>
                            </> : ''
                        }
                    </Row>
                </Container>
                <Footer />
            </>
        );
    }
}

Polling.propTypes = {
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    apiErrorModalHandleShow: PropTypes.func.isRequired,
};

export default Polling;
