import React from 'react';
import {Container, Row, Col, Card} from 'react-bootstrap';
import {Bar, Pie, HorizontalBar} from 'react-chartjs-2';
import BootstrapTable from 'react-bootstrap-table-next';
import PropTypes from 'prop-types';
import {CSVLink} from 'react-csv';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDownload} from '@fortawesome/free-solid-svg-icons';
import {landingPageUrl} from '../config';
import TopBar from '../components/topBar';
import Footer from '../components/footer';
import Breadcrumbs from '../components/breadcrumbs';
import authManager from '../utils/authManager';
import logManager from '../utils/logManager';
import ApiError from '../utils/apiError';
import DatePicker from 'react-datepicker';
import {differenceInDays, subMonths} from 'date-fns';
// import ApiFetch from '../utils/apiFetch';
class Home extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            startDate: new Date(), // This Date For Date Picker
            dateNo: 1,
            datesArray: [], // week dates Array
            data: {},
            mdmStatusChart: {},
            consumerStatusChart: {},
            meterStatusChart: {},
        };
        this.handleChange =this.handleChange.bind(this); // This Function For Handle Date Picker input
        this.lastWeeksDate = this.lastWeeksDate.bind(this); // this function get All date in array form
        this.renderMeterStatusChartData = this.renderMeterStatusChartData.bind(this);
        this.getInventoryChartData = this.getInventoryChartData.bind(this);
        this.renderMDMStatusChartData = this.renderMDMStatusChartData.bind(this);
        this.getMDMStatusChartDataLastRecordDate = this.getMDMStatusChartDataLastRecordDate.bind(this);
        this.renderConsumerStatusChartData = this.renderConsumerStatusChartData.bind(this);
        this.getConsumerStatusChartDataLastReceivedDate = this.getConsumerStatusChartDataLastReceivedDate.bind(this);
    }
    handleChange(date) { // this function for date-picker inpur read date
        this.setState({
            startDate: date,
        });
        let presentDate = new Date();
        let difference = differenceInDays(presentDate,date)
        this.fetchData(`${difference !== 0 ? difference : '' }`)
            .then((data) => {
            this.setState({data});
            this.renderMDMStatusChartData();
            this.renderConsumerStatusChartData();
            this.renderMeterStatusChartData();
        })
        .catch((error) => {
            this.setState({data: {}});
        });      
    };
    // fetchDataByDate(DateNo){
    //     this.props.showLoader();
    //     const myHeaders = new Headers();
    //     myHeaders.append("Content-Type", "application/json");
    //     myHeaders.append("API-Key", authManager.getApiKey());
    
    //     const requestOptions = {
    //       method: "GET",
    //       headers: myHeaders,
    //       redirect: "follow",
    //     };
        
    //     return ApiFetch(this.props, landingPageUrl+"|"+DateNo, requestOptions);

    // }

    lastWeeksDate(date) { // Last Weeks all date
        const currentDate = new Date(date?date:Date.now());
        const datesArray = [];
        this.setState({
            datesArray: datesArray,
        });
        // for (let i=0; i<7; i++) {
        for (let i=1; i<=7; i++) {
            let newDate = currentDate;
            newDate = new Date(currentDate-i*24*60*60*1000).toISOString().split('T')[0];
            datesArray.push(newDate);
        };
        return datesArray;
    };
    getInventoryChartData() {
        const [installed, inStock] = this.state.data.mtr_stat;
        const inventoryChartData = {
            labels: [
                installed.Des,
                inStock.Des,
            ],
            datasets: [{
                data: [installed.Kount, inStock.Kount],
                backgroundColor: [
                    '#3A6391',
                    '#F17767',
                ],
                hoverBackgroundColor: [
                    '#3A6391',
                    '#F17767',
                ],
            }],
        };

        return inventoryChartData;
    }

    getInventoryChartOption() {
        const option = {
            legend: {
                position: 'top',
                align: 'start',
            },
            maintainAspectRatio: false,
            responsive: true,
        };
        return option;
    }

    getMDMStatusChartDataLastRecordDate(index) {
        return this.state.mdmStatusChart.totalData[index].Last_Reced_date;
    }

    getConsumerStatusChartDataLastReceivedDate(index) {
        return this.state.consumerStatusChart.totalData[index].last_received;
    }

    async renderMDMStatusChartData() {
        const that = this;
        const labels = [];
        const barData = [];
        const totalData = [];
        this.state.data.mdm_data.forEach((element) => {
            labels.push(element.des);
            barData.push(element.efficiency);
            totalData.push(element);
        });

        const data = {
            datasets: [{
                type: 'bar',
                label: 'Efficiency',
                data: barData,
                fill: false,
                backgroundColor: '#05B1A2',
                borderColor: '#05B1A2',
                hoverBackgroundColor: '#05B1A2',
                hoverBorderColor: '#05B1A2',
                yAxisID: 'y-axis-1',
            }],
        };

        const options = {
            responsive: true,
            maintainAspectRatio: false,
            title: {
                display: false,
                text: 'MDM Status',
                fontSize: 10,
            },
            tooltips: {
                mode: 'label',
                callbacks: {
                    afterLabel: function(tooltipItem, data) {
                        return `Last Record Date: ${that.getMDMStatusChartDataLastRecordDate(tooltipItem.index)}`;
                    },
                },
            },
            legend: {
                position: 'top',
                align: 'start',
            },
            elements: {
                line: {
                    fill: false,
                },
            },
            scales: {
                xAxes: [
                    {
                        display: true,
                        gridLines: {
                            display: true,
                            drawBorder: true,
                        },

                        labels: labels,
                        scaleLabel: {
                            display: false,
                            labelString: 'Type',
                        },
                    },
                ],
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-1',
                        gridLines: {
                            display: true,
                            drawBorder: true,
                        },
                        labels: {
                            show: true,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Efficiency (%)',
                        },
                    },
                ],
            },
        };

        const mdmStatusChart = {
            show: true,
            data,
            options,
            totalData,
        };
        this.setState({mdmStatusChart});
    }

    async renderConsumerStatusChartData() {
        const that = this;
        const labels = [];
        const barData = [];
        const totalData = [];
        this.state.data.con_stat.sort((a, b) => {
            return b.hrs - a.hrs;
        });

        this.state.data.con_stat.forEach((element) => {
            labels.push(element.device_id);
            barData.push(element.hrs);
            totalData.push(element);
        });

        const data = {
            labels,
            datasets: [{
                type: 'horizontalBar',
                label: 'Hours',
                data: barData,
                fill: false,
                backgroundColor: '#05B1A2',
                borderColor: '#05B1A2',
                hoverBackgroundColor: '#05B1A2',
                hoverBorderColor: '#05B1A2',
                // yAxisID: 'y-axis-1',
            }],
        };

        const options = {
            responsive: true,
            maintainAspectRatio: false,
            title: {
                display: false,
                text: 'Consumer not read for long time',
                fontSize: 10,
            },
            tooltips: {
                mode: 'label',
                callbacks: {
                    afterLabel: function(tooltipItem, data) {
                        return `Last Received Date: ${that.getConsumerStatusChartDataLastReceivedDate(tooltipItem.index)}`;
                    },
                },
            },
            elements: {
                line: {
                    fill: false,
                },
            },
            legend: {
                position: 'top',
                align: 'start',
            },
            scales: {
                xAxes: [
                    {
                        display: true,
                        gridLines: {
                            display: true,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Hours',
                        },
                    },
                ],
                yAxes: [
                    {
                        display: true,
                        gridLines: {
                            display: true,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Meter No',
                        },
                    },
                ],
            },
        };

        const consumerStatusChart = {
            show: true,
            data,
            options,
            totalData,
        };
        this.setState({consumerStatusChart});
    }

    async renderMeterStatusChartData() {
        // const labels = this.lastWeeksDate(this.state.startDate);
        const labels = [];
        const barData1 = [];
        const barData2 = [];
        const totalData = [];

        const [totalStock, , , ...meterData] = this.state.data.mtr_stat;
        meterData.forEach((element) => {
            labels.push(element.Des);
            barData1.push(element.Kount);
            barData2.push(totalStock.Kount - element.Kount);
            totalData.push(element);
            // console.log(element);
        });
        // console.log(this.state.data.mtr_stat);

        const data = {
            datasets: [
                {
                    label: 'Read',
                    data: barData1,
                    fill: false,
                    backgroundColor: '#3A6391',
                    borderColor: '#3A6391',
                    hoverBackgroundColor: '#3A6391',
                    hoverBorderColor: '#3A6391',
                    stack: 'stack1',
                    yAxisID: 'y-axis-1',
                },
                {
                    label: 'Not read',
                    data: barData2,
                    fill: false,
                    backgroundColor: '#F17767',
                    borderColor: '#F17767',
                    hoverBackgroundColor: '#F17767',
                    hoverBorderColor: '#F17767',
                    stack: 'stack1',
                    yAxisID: 'y-axis-1',
                },
            ],
        };

        const options = {
            responsive: true,
            maintainAspectRatio: false,
            title: {
                display: false,
                text: 'Reading trend',
                fontSize: 10,
            },
            tooltips: {
                mode: 'label',
            },
            legend: {
                position: 'top',
                align: 'start',
            },
            elements: {
                line: {
                    fill: false,
                },
            },
            scales: {
                xAxes: [
                    {
                        display: true,
                        gridLines: {
                            display: false,
                        },

                        labels: labels,
                        // labels: this.lastWeeksDate(date),
                        scaleLabel: {
                            display: true,
                            labelString: 'Date (YYYY-MM-DD)',
                        },
                    },
                ],
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-1',
                        gridLines: {
                            display: false,
                        },
                        labels: {
                            show: true,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Count',
                        },
                        stacked: true,
                    },
                ],
            },
        };

        const meterStatusChart = {
            show: true,
            data,
            options,
            totalData,
        };
        // console.log({data, options, totalData});
        this.setState({meterStatusChart});
    }

    componentDidMount() {
        this.fetchData()
            .then((data) => {
                this.setState({data});
                this.renderMDMStatusChartData();
                this.renderConsumerStatusChartData();
                this.renderMeterStatusChartData();
            })
            .catch((error) => {
                this.setState({data: {}});
            });
    }

    fetchData(DateNo) {
        this.props.showLoader();
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('API-Key', authManager.getApiKey());

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };
        let urlString= landingPageUrl;
        if(!DateNo){
            urlString=urlString;
        }else{
             urlString=urlString+"/"+DateNo;
        }
        console.log(DateNo,'fromApi')
        return fetch(urlString, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((responseBody) => {
                        throw new ApiError(response.status, response.statusText, responseBody.Status);
                    });
                }
                return response.json();
            })
            .then((data) => {
                logManager.info(data);
                this.props.hideLoader();
                return Promise.resolve(data);
            })
            .catch((error) => {
                this.props.hideLoader();
                logManager.error(`statusCode : ${error.code} & statusMessage : ${error.message} & apiMessage : ${error.apiMessage}`);
                this.props.apiErrorModalHandleShow(error.code, error.message, error.apiMessage);
                return Promise.reject(new Error(error.apiMessage));
            });
    }

    render() {
        return (
            <>
                <TopBar />
                <Container fluid className="shadow p-4 bg-white rounded">
                    <Breadcrumbs title="Communication status" />
                    <Row>
                        <Col md="3" className="shadow p-3 mb-2 bg-white rounded">
                            <Row>
                                <Col md="12">
                                    <Card className="mb-2" style={{minHeight: '250px'}}>
                                        <Card.Header>
                                            Inventory
                                            <CSVLink
                                                data={this.state.data.mtr_stat ? this.state.data.mtr_stat : ''}
                                                filename={'inventory.csv'}
                                                className="btn btn-primary btn-sm float-right"
                                                target="_blank"
                                            >
                                                <FontAwesomeIcon icon={faDownload} />
                                            </CSVLink>
                                        </Card.Header>
                                        <Card.Body>
                                            {this.state.data.mtr_stat ?
                                                <Pie
                                                    data={this.getInventoryChartData()}
                                                    options={this.getInventoryChartOption()}
                                                /> : ''
                                            }
                                        </Card.Body>
                                    </Card>
                                    <Card style={{minHeight: '300px'}}>
                                        <Card.Header>
                                            MDM Status
                                            <CSVLink
                                                data={this.state.mdmStatusChart.totalData ? this.state.mdmStatusChart.totalData : ''}
                                                filename={'mdm_status.csv'}
                                                className="btn btn-primary btn-sm float-right"
                                                target="_blank"
                                            >
                                                <FontAwesomeIcon icon={faDownload} />
                                            </CSVLink>
                                        </Card.Header>
                                        <Card.Body>
                                            {this.state.mdmStatusChart.show ?
                                                <Bar
                                                    data={this.state.mdmStatusChart.data}
                                                    options={this.state.mdmStatusChart.options}
                                                /> : ''
                                            }
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col md="9" className="shadow p-3 mb-2 bg-white rounded">
                            <Row>
                                <Col md="6">
                                    <Card className="mb-2" style={{minHeight: '350px'}}>
                                        <Card.Header className='float-right' >
                                            <span>Reading trend</span>
                                            <div className='float-right d-flex justify-content-around gap-4'>
                                                <DatePicker // date picker in Reading trend From
                                                    className=" form-control me-2 form-control-sm  "
                                                    selected={this.state.startDate}
                                                    onChange={this.handleChange}
                                                    minDate={subMonths(new Date(), 3)}
                                                    maxDate={new Date()}
                                                    name="startDate"
                                                    placeholderText="From Date"
                                                    dateFormat="yyyy-MM-dd"
                                                />
                                                <CSVLink
                                                    data={this.state.meterStatusChart.totalData ? this.state.meterStatusChart.totalData : ''}
                                                    filename={'reading_trend.csv'}
                                                    className="btn btn-primary btn-sm"
                                                    target="_blank"
                                                >
                                                    <FontAwesomeIcon icon={faDownload} />
                                                </CSVLink>
                                            </div>
                                        </Card.Header>
                                        <Card.Body>
                                            {this.state.meterStatusChart.show ?
                                                <Bar
                                                    data={this.state.meterStatusChart.data}
                                                    options={this.state.meterStatusChart.options}
                                                /> : ''
                                            }
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md="6">
                                    <Card className="mb-2" style={{minHeight: '350px'}}>
                                        <Card.Header>
                                            Consumer not read for long time
                                            <CSVLink
                                                data={this.state.data.con_stat ? this.state.data.con_stat : ''}
                                                filename={'consumer_status.csv'}
                                                className="btn btn-primary btn-sm float-right"
                                                target="_blank"
                                            >
                                                <FontAwesomeIcon icon={faDownload} />
                                            </CSVLink>
                                        </Card.Header>
                                        <Card.Body>
                                            {this.state.consumerStatusChart.show ?
                                                <HorizontalBar
                                                    data={this.state.consumerStatusChart.data}
                                                    options={this.state.consumerStatusChart.options}
                                                /> : ''
                                            }
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </>
        );
    }
}

Home.propTypes = {
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    apiErrorModalHandleShow: PropTypes.func.isRequired,
};

export default Home;
