import React, { useState } from "react";
// import { DataGrid } from '@material-ui/core';

import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import MUIDataTable from "mui-datatables";

export default function ReadingReportTable(props) {
  const data = props.data;
  const rowsValues = [...data];
  const columnsValues = [
    { name: "device_id", label: "Device Id" },
    {name: "meter_type", label: "Meter Type"},
    { name: "sampl_tm", label: "Sample TM" },
    { name: "act_valu", label: "Act Value" },
    { name: "fmt_valu", label: "FMT Value" },
    {name: "parameter_des", label: "Parameter Description"},
    { name: "profile_name", label: "Profile Name" },
    { name: "mgc_group_cd", label: "MGC Group" },
    { name: "param_group", label: "Param Group" },
    { name: "mgc_subgroup_cd",label: "MGC Sub Group" },
    {name: "param_subgroup", label: "Param Sub Group"},

  ];
 

 

  const getMuiTheme = () => {
    return createMuiTheme({
      overrides: {
        MUIDataTableHeadCell: {
          root: {
            backgroundColor: "#3A6391 !important",
            fontWeight: "bold",
            fontSize: 14,
            color: "#ffffff",
            
          },
        },
        MuiTableCell: {
          root: {
            padding: "5px",
          },
        },
      },
    });
  };

  const getTableOptions = () => {
    const options = {
      filterType: "textField",
      selectableRows: false,
      draggableColumns: {
        enabled: true,
      },
      fixedHeader: true,
    };
    return options;
  };
  // console.log(rowsValu);
  return (
    <>
      {/* <div className="mt-3 card  h-75 ">
        <div className=" bg-light p-3  rounded">
          <span>Reading Report</span>
          <CSVLink
            data={data ? data : ""}
            filename={"reading_trenzs.csv"}
            className="btn btn-primary btn-sm float-right"
            target="_blank"
          >
            <FontAwesomeIcon icon={faDownload} />
          </CSVLink>
        </div>
        <div className="  table-responsive border">
          <table className="table table-borderless table-hover  p-0 m-0">
            <thead className=" ">
              <tr className="">
                {columnsValues.map((col) => (
                  <th
                    className="bg-secondary text-white  border-top-0  "
                    style={{
                      position: "sticky",
                      top: 0,
                    }}
                    key={`${col.label}-header`}
                  >
                    {col.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rowsValues?.map((ele, index) => {
                return (
                  <tr key={index}>
                    {Object.values(ele).map((value, i) => (
                      <td
                        className="border-bottom border-right-0 border-left-0"
                        key={i}
                      >
                        {value}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div> */}
      <div className="card " style={{zindex:-999}}>
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            data={rowsValues}
            title={"Reading Reports"}
            columns={columnsValues}
            options={getTableOptions()}
          />
        </MuiThemeProvider>
      </div>
    </>
  );
}
