import {reactLocalStorage} from 'reactjs-localstorage';
import logManager from './logManager';
import _ from 'lodash';
class AuthManager {
    constructor() {
        const user = reactLocalStorage.getObject('user');
        this.user = _.isEmpty(user) ? null : user;
    }

    getUser() {
        logManager.info(`getUser called`, 'AuthManager');
        const user = this.user || reactLocalStorage.getObject('user');
        return _.isEmpty(user)? null : user;
    }

    setUser(user) {
        logManager.info(`setUser called`, 'AuthManager');
        const obj = {};
        obj['status'] = user.status;
        obj['userRealName'] = user.mdm_user;
        obj['emailId'] = user.email_id;
        obj['location'] = user.location;
        obj['pinCode'] = user.pin_code;
        obj['company'] = user.company;
        obj['accessType'] = user.access_type;
        obj['lastLogged'] = user.last_logged;
        obj['apiKey'] = user['API-Key'];
        reactLocalStorage.setObject('user', obj);
        return true;
    }

    unsetUser() {
        logManager.info(`unsetUser called`, 'AuthManager');
        reactLocalStorage.remove('user');
        return true;
    }

    getApiKey() {
        logManager.info(`getApiKey called`, 'AuthManager');
        return this.getUser().apiKey || null;
    }

    getMerchantId() {
        logManager.info(`getApiKey called`, 'AuthManager');
        return this.getUser().company || null;
    }

    getPermission() {
        const accessType = this.getUser().accessType;
        // const accessType = 'View';
        if (accessType === 'Add') {
            return {
                edit: true,
                add: true,
                view: true,
                download: true,
            };
        } else if (accessType === 'Print') {
            return {
                edit: false,
                add: false,
                view: true,
                download: true,
            };
        } else if (accessType === 'View') {
            return {
                edit: false,
                add: false,
                view: true,
                download: false,
            };
        }
    }
}

export default new AuthManager();
