import React from 'react';
import {useForm} from 'react-hook-form';
import Brandpic from '../assets/logo.png';
import {useHistory, Link} from 'react-router-dom';
import * as yup from 'yup';
import {defaultApiKey, loginApiUrl, landingPage, allRoutes, currentMerchant} from '../config';
import '../assets/login.css';
import ApiError from '../utils/apiError';
import PropTypes from 'prop-types';
import authManager from '../utils/authManager';
import Footer from '../components/footer';

const schema = yup.object().shape({
    username: yup.string().required('Mobile No is required'),
    password: yup.string().required('Password is required'),
});

const Login = (props) => {
    const {register, handleSubmit, errors} = useForm({
        validationSchema: schema,
    });
    const history = useHistory();

    const onSubmit = (data) => {
        props.showLoader();
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('API-Key', defaultApiKey);

        const raw = JSON.stringify({'user_name': data.username, 'password': data.password});

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
        };

        fetch(loginApiUrl + `?id=${currentMerchant}`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((responseBody) => {
                        throw new ApiError(response.status, response.statusText, responseBody.Status);
                    });
                }
                return response.json();
            })
            .then((result) => {
                authManager.setUser(result);
                props.hideLoader();
                history.replace(landingPage);
            })
            .catch((error) => {
                props.hideLoader();
                console.error(`statusCode : ${error.code} & statusMessage : ${error.message} & apiMessage : ${error.apiMessage}`);
                props.apiErrorModalHandleShow(error.code, error.message, error.apiMessage);
            });
    };
    return (
        <>
            <div className="container h-100">
                <div className="d-flex justify-content-center h-100">
                    <div className="user_card">
                        <div className="d-flex justify-content-center">
                            <div className="brand_logo_container">
                                <img src={Brandpic} className="brand_logo" alt="Logo" />
                            </div>
                        </div>
                        <div className="d-flex justify-content-center form_container">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="mb-2">
                                    <div className="input-group">
                                        <div className="input-group-append">
                                            <span className="input-group-text"><i className="fas fa-user"></i></span>
                                        </div>
                                        <input type="text" name="username" className="form-control input_user"
                                            placeholder="Mobile No" ref={register} />
                                    </div>
                                    {errors.username && <span className="input-error">{errors.username.message}</span>}
                                </div>
                                <div className="mb-2">
                                    <div className="input-group">
                                        <div className="input-group-append">
                                            <span className="input-group-text"><i className="fas fa-key"></i></span>
                                        </div>
                                        <input type="password" name="password" className="form-control input_pass"
                                            placeholder="Password" ref={register} />
                                    </div>
                                    {errors.password && <span className="input-error">{errors.password.message}</span>}
                                </div>
                                <div className="d-flex justify-content-center mt-3 login_container">
                                    <button type="submit" name="button" className="btn login_btn">Login</button>
                                </div>
                            </form>
                        </div>
                        <div className="mt-4">
                            <div className="d-flex justify-content-center links">
                                Don&#39;t have an account? <Link to={allRoutes.signUp} className="ml-2">Sign Up</Link>
                            </div>
                            <div className="d-flex justify-content-center links">
                                <Link to={allRoutes.forgotPassword}>Forgot your password?</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

Login.propTypes = {
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    apiErrorModalHandleShow: PropTypes.func.isRequired,
};

export default Login;
