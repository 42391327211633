import React from 'react';
import {useForm} from 'react-hook-form';
import {Form, Col, Button} from 'react-bootstrap';
import * as yup from 'yup';
import PropTypes from 'prop-types';

const schema = yup.object().shape({
    old_passwd: yup.string().required('Old Password is required'),
    new_passwd: yup.string().required('New Password is required')
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .matches(/[a-z]/, 'Password must have atleast one Lower case character.')
        .matches(/[A-Z]/, 'Password must have atleast one Upercase case character.')
        .matches(/\d/, 'Password must have one atleast Number.')
        .matches(/[\W_]/, 'Password must have atleast one Symbol.'),
    new_confirm_passwd: yup.string().oneOf([yup.ref('new_passwd'), null],
        'New Passwords must match with New Confirm Password'),
});

const ChangePassword = (props) => {
    const {register, handleSubmit, errors, reset} = useForm({
        validationSchema: schema,
    });

    const onSubmit = (data) => {
        props.changePasswordSave(data);
    };

    const handleCancel = () => {
        reset();
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md="6" controlId="old_passwd">
                    <Form.Label>Old Password</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Old Password"
                        name="old_passwd"
                        ref={register}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.old_passwd && errors.old_passwd.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md="6" controlId="new_passwd">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="New Password"
                        name="new_passwd"
                        ref={register}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.new_passwd && errors.new_passwd.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md="6" controlId="new_confirm_passwd">
                    <Form.Label>New Confirm Password</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="New Confirm Password"
                        name="new_confirm_passwd"
                        ref={register}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.new_confirm_passwd && errors.new_confirm_passwd.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md="6" controlId="submit">
                    <Button
                        type="submit"
                        className="float-right ml-1"
                    >Submit</Button>
                    <Button className="float-right" onClick={handleCancel}>Reset</Button>
                </Form.Group>
            </Form.Row>
        </Form>
    );
};

ChangePassword.propTypes = {
    changePasswordSave: PropTypes.func.isRequired,
};

export default ChangePassword;
