import React from 'react';
import {Navbar, Nav, NavDropdown} from 'react-bootstrap';
import {Link, NavLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import authManager from '../utils/authManager';
import {allRoutes} from './../config';

function TopBar(props) {
    const user = authManager.getUser();
    let greentingMessage = 'Welcome, User';
    if (user) {
        const firstName = user.userRealName.split(' ')[0] || 'User';
        greentingMessage = 'Welcome, ' + firstName;
    }

    return (
        <Navbar sticky="top" bg="dark" variant="dark" expand="lg" className="navbar-custom">
            <Navbar.Brand as={Link} to={allRoutes.root}>MDM-Lite</Navbar.Brand>
            {(props.noMenu === undefined) ?
                <>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link as={NavLink} to={allRoutes.home}>Home</Nav.Link>
                            <NavDropdown title="Entry" id="basic-nav-dropdown">
                                <NavDropdown.Item as={NavLink} to={allRoutes.entryMeter}>Meters</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to={allRoutes.entryConsumer}>Consumer</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Reports" id="basic-nav-dropdown">
                                <NavDropdown.Item as={NavLink} to={allRoutes.reportConsumerDemand}>Consumption</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to={allRoutes.reportLoadSurvey}>Load Survey</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to={allRoutes.reportInstantaneous}>Instantaneous</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to={allRoutes.reportEvent}>Events</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to={allRoutes.reportReading}>Reading</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to={allRoutes.reportReadingParam}>Reading Parameter</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to={allRoutes.hesStatus}>HES Status</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Meter" id="basic-nav-dropdown">
                                <NavDropdown.Item as={NavLink} to={allRoutes.polling}>Polling</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to={allRoutes.billing}>Billing</NavDropdown.Item>
                                <NavDropdown.Divider></NavDropdown.Divider>
                                <NavDropdown.Item as={NavLink} to={allRoutes.disConnection}>Disconnection</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to={allRoutes.reConnection}>Re-connection</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                        <Nav className="float-lg-right">
                            <NavDropdown title={greentingMessage} id="basic-nav-dropdown">
                                <NavDropdown.Item as={NavLink} to={allRoutes.profile}>Profile</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to={allRoutes.support}>Contact Support</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item as={NavLink} to={allRoutes.logOut}>Logout</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </> : ''
            }
        </Navbar>
    );
}

TopBar.propTypes = {
    noMenu: PropTypes.any,
};

export default TopBar;
