import React from 'react';
import {Container, Row, Col, Button} from 'react-bootstrap';
import TopBar from '../../../components/topBar';
import Footer from '../../../components/footer';
import logManager from '../../../utils/logManager';
import ApiError from '../../../utils/apiError';
import authManager from '../../../utils/authManager';
import {devicesApiUrl, lovApiUrl, individualDeviceUrl} from '../../../config';
import MUIDataTable from 'mui-datatables';
import ActionButtons from '../../../components/actionButtons';
import PropTypes from 'prop-types';
import MeterForm from './form';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

class Meters extends React.Component {
    constructor(props) {
        super();
        this.props = props;
        this.state = {
            data: [],
            deviceToEdit: {},
            permission: [],
            showForm: false,
            listOfValues: {},
            isAdd: false,
        };

        this.handleEditRow = this.handleEditRow.bind(this);
        this.handleDeleteRow = this.handleDeleteRow.bind(this);
        this.handleAddNew = this.handleAddNew.bind(this);
        this.handleAddNewCancel = this.handleAddNewCancel.bind(this);
        this.showSuccessfulMessage = this.showSuccessfulMessage.bind(this);
    }

    fetchDevices() {
        this.props.showLoader();
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('API-Key', authManager.getApiKey());

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        return fetch(devicesApiUrl, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((responseBody) => {
                        throw new ApiError(response.status, response.statusText, responseBody.Status);
                    });
                }
                return response.json();
            })
            .then((devices) => {
                logManager.info(devices);
                this.props.hideLoader();
                return Promise.resolve(devices);
            })
            .catch((error) => {
                this.props.hideLoader();
                logManager.error(`statusCode : ${error.code} & statusMessage : ${error.message} & apiMessage : ${error.apiMessage}`);
                this.props.apiErrorModalHandleShow(error.code, error.message, error.apiMessage);
            });
    }

    fetchDeviceDetails(device) {
        this.props.showLoader();
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('API-Key', authManager.getApiKey());

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        return fetch(individualDeviceUrl + device.device_id, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((responseBody) => {
                        throw new ApiError(response.status, response.statusText, responseBody.Status);
                    });
                }
                return response.json();
            })
            .then((result) => {
                logManager.info(result);
                this.props.hideLoader();
                return Promise.resolve(result);
            })
            .catch((error) => {
                this.props.hideLoader();
                logManager.error(`statusCode : ${error.code} & statusMessage : ${error.message} & apiMessage : ${error.apiMessage}`);
                this.props.apiErrorModalHandleShow(error.code, error.message, error.apiMessage);
            });
    }

    async fetchLOV() {
        // this.props.showLoader();
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('API-Key', authManager.getApiKey());

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        return fetch(lovApiUrl, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((responseBody) => {
                        throw new ApiError(response.status, response.statusText, responseBody.Status);
                    });
                }
                return response.json();
            })
            .then((listOfValues) => {
                logManager.info(listOfValues);
                // this.props.hideLoader();
                return Promise.resolve(listOfValues.meter_data);
            })
            .catch((error) => {
                // this.props.hideLoader();
                logManager.error(`statusCode : ${error.code} & statusMessage : ${error.message} & apiMessage : ${error.apiMessage}`);
                this.props.apiErrorModalHandleShow(error.code, error.message, error.apiMessage);
            });
    }

    async componentDidMount() {
        this.setState({permission: authManager.getPermission()});

        const response = await this.fetchDevices();

        this.fetchLOV().then((listOfValues) => {
            this.setState({listOfValues});
        });

        this.setState({
            data: response,
        });
    }

    handleEditRow(id) {
        this.fetchDeviceDetails(this.state.data[id])
            .then((result) => {
                this.setState({deviceToEdit: result, showForm: true});
            });
    }

    handleDeleteRow(id) {
    }

    handleAddNew() {
        this.setState({showForm: true, isAdd: true, deviceToEdit: {}});
    }

    handleAddNewCancel() {
        this.setState({showForm: false, isAdd: false, deviceToEdit: {}});
    }

    async showSuccessfulMessage(message) {
        this.setState({showForm: false, isAdd: false});
        NotificationManager.success(message, 'Success');
        const response = await this.fetchDevices();
        this.setState({
            data: response,
        });
    }

    getTableColumns() {
        const columns = [
            {
                name: 'device_id',
                label: 'Meter Number',
                options: {
                    filter: true,
                    sort: true,
                    filterType: 'textField',
                },
            },
            {
                name: 'manufact_name',
                label: 'Make',
                options: {
                    filter: true,
                    sort: true,
                    filterType: 'dropdown',
                },
            },
            {
                name: 'merchant_id',
                label: 'Utility ID',
                options: {
                    filter: true,
                    sort: false,
                    filterType: 'dropdown',
                },
            },
            {
                name: 'meter_type',
                label: 'Meter Type',
                options: {
                    filter: true,
                    sort: false,
                    filterType: 'dropdown',
                },
            },
            {
                name: 'nic_id',
                label: 'NIC ID',
                options: {
                    filter: true,
                    sort: false,
                    filterType: 'textField',
                },
            },
            {
                name: 'connection',
                label: 'Connection',
                options: {
                    filter: true,
                    sort: false,
                    filterType: 'checkbox',
                },
            },
            {
                name: 'position',
                label: 'Position',
                options: {
                    filter: true,
                    sort: false,
                    filterType: 'dropdown',
                },
            },
            {
                name: 'status',
                label: 'Status',
                options: {
                    filter: true,
                    sort: false,
                    filterType: 'checkbox',
                },
            },
        ];
        if (this.state.permission.edit) {
            columns.push({
                name: 'action_buttons',
                label: 'Actions',
                options: {
                    filter: false,
                    sort: false,
                    print: false,
                    searchable: false,
                    download: false,
                    customBodyRenderLite: (dataIndex, rowIndex) => {
                        return (
                            <ActionButtons
                                showEdit
                                // showDelete
                                data={{dataIndex, rowIndex}}
                                editOnClick={this.handleEditRow}
                                deleteOnClick={this.handleDeleteRow}
                            />
                        );
                    },
                },
            });
        }

        return columns;
    }

    getTableOptions() {
        const options = {
            filterType: 'checkbox',
            selectableRows: false,
            draggableColumns: {
                enabled: true,
            },
            fixedHeader: true,
        };
        if (this.state.permission.download) {
            options.download = true;
            options.print = true;
        } else {
            options.download = false;
            options.print = false;
        }

        return options;
    }

    getMuiTheme() {
        return createMuiTheme({
            overrides: {
                MUIDataTableHeadCell: {
                    root: {
                        backgroundColor: '#3A6391 !important',
                        fontWeight: 'bold',
                        fontSize: 14,
                        color: '#ffffff',
                    },
                },
                MuiTableCell: {
                    root: {
                        padding: '5px',
                    },
                },
            },
        });
    }

    render() {
        const columns = this.getTableColumns();
        const options = this.getTableOptions();
        return (
            <>
                <TopBar />
                <Container fluid className="shadow p-4 bg-white rounded">
                    {this.state.permission.add && !this.state.showForm ?
                        <Row>
                            <Col md="12">
                                <Button className="float-right" variant="primary" size="md"
                                    onClick={this.handleAddNew}><i className="fas fa-plus"></i> Add New</Button>
                            </Col>
                        </Row> : ''
                    }
                    {this.state.showForm ?
                        <MeterForm
                            listOfValues={this.state.listOfValues}
                            handleCancel={this.handleAddNewCancel}
                            showSuccessfulMessage={this.showSuccessfulMessage}
                            device={this.state.deviceToEdit}
                            isAdd={this.state.isAdd}
                            {...this.props}
                        /> :
                        <MuiThemeProvider theme={this.getMuiTheme()}>
                            <MUIDataTable
                                title={'Meter List'}
                                data={this.state.data}
                                columns={columns}
                                options={options}
                            />
                        </MuiThemeProvider>
                    }
                    <NotificationContainer />
                </Container>
                <Footer />
            </>
        );
    }
}

Meters.propTypes = {
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    apiErrorModalHandleShow: PropTypes.func.isRequired,
};

export default Meters;
