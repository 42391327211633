export const footerText = '© Mega Calibre Enterprises Pvt. Ltd.';
export const serverRootUrl = process.env.REACT_APP_SERVER_URL;
export const serverPort = process.env.REACT_APP_SERVER_PORT;

export const apiUrl = serverRootUrl + ':' + serverPort + '/api/' + process.env.REACT_APP_SERVER_API_VERSION + '/';
export const defaultApiKey = process.env.REACT_APP_SERVER_DEFAULT_API_KEY;
export const loginApiUrl = apiUrl + 'check';
export const signupApiUrl = apiUrl + 'users/new';
export const devicesApiUrl = apiUrl + 'devicelist';
export const demandApiUrl = apiUrl + 'demand/';
export const lovApiUrl = apiUrl + 'listofvalues';
export const locationList = apiUrl + 'locationlist';
export const consumerByLocation = apiUrl + 'consumersbylocation/';
export const consumerByLocationParam = apiUrl + 'consumersbylocationparam/';
export const createOtpUrl = apiUrl + 'createotp/0000';
export const verifyOtpUrl = apiUrl + 'createotp/';
export const individualDeviceUrl = apiUrl + 'getdevice/';
export const individualConsumerUrl = apiUrl + 'getconsumer/';
export const consumerListUrl = apiUrl + 'consumerlist/';
export const landingPageUrl = apiUrl + 'landingpage';
export const deviceAdd = apiUrl + 'devices/new';
export const deviceEdit = apiUrl + 'devices/edit';
export const pollDeviceUrl = apiUrl + 'polldevice';
export const checkPollStatusUrl = apiUrl + 'checkpollstatus/';
export const loadSurveyUrl = apiUrl + 'loadsurvey/';
export const instantUrl = apiUrl + 'instant/';
export const eventsUrl = apiUrl + 'events/';
export const consumerAdd = apiUrl + 'consumers/new';
export const consumerEdit = apiUrl + 'consumers/edit';
export const forgotPasswordUrl = apiUrl + 'forget';
export const sendPasswordUrl = apiUrl + 'sendpass/';
export const getUserUrl = apiUrl + 'getuser';
export const updateProfileUrl = apiUrl + 'users/edit';
export const changePasswordUrl = apiUrl + 'changepass';
export const billingUrl = apiUrl + 'billing/';
export const readingReportsUrl = apiUrl + 'readingreport/';
export const readingParamReportsUrl = apiUrl + 'readingparamreport/';

export const securityQuestions = [
    {value: 'What is your pet\'s name ?', label: 'What is your pet\'s name ?'},
    {value: 'What is your mother\'s name ?', label: 'What is your mother\'s name ?'},
    {value: 'Which city were you born ?', label: 'Which city were you born ?'},
    {value: 'What is your favourite color ?', label: 'What is your favourite color ?'},
    {value: 'What is your nick name ?', label: 'What is your nick name ?'},
];

export const baseUrl = process.env.REACT_APP_CLIENT_URL;
export const currentMerchant = window.appConfig.merchant;
export const basePath = '/' + currentMerchant;
export const allRoutes = {
    root: basePath + '/',
    home: basePath + '/home',
    signUp: basePath + '/signup',
    forgotPassword: basePath + '/forgot-password',
    entryMeter: basePath + '/entry/meters',
    entryConsumer: basePath + '/entry/consumers',
    reportConsumerDemand: basePath + '/reports/consumption-demand',
    reportLoadSurvey: basePath + '/reports/load-survey',
    reportInstantaneous: basePath + '/reports/instantaneous',
    reportEvent: basePath + '/reports/events',
    polling: basePath + '/meter-management/polling',
    billing: basePath + '/meter-management/billing',
    profile: basePath + '/profile',
    logOut: basePath + '/logout',
    disConnection: basePath + '/meter-management/disconnection',
    reConnection: basePath + '/meter-management/re-connection',
    support: basePath + '/support',
    reportReading: basePath + '/reports/reading',
    reportReadingParam: basePath + '/reports/readingParam',
    hesStatus: basePath + '/reports/hesStatus',
};
export const landingPage = allRoutes.home;
export const validMerchants = [
    {
        id: 'MEGADEMO',
        name: 'MEGA Demo',
        description: 'This merchant used for the demo purpose',
    },
    {
        id: 'IITKN01',
        name: 'IIT Kanpur',
        description: 'This merchant is for IIT Kanpur',
    },
    {
        id: 'IITKN02',
        name: 'IIT Kanpur Second',
        description: 'This merchant is for IIT Kanpur Second',
    },
];
