import React, {useState} from 'react';
import {Form, Col, Button, InputGroup, Container} from 'react-bootstrap';
import {defaultApiKey, forgotPasswordUrl, sendPasswordUrl, allRoutes, currentMerchant} from '../config';
import PropTypes from 'prop-types';
import TopBar from '../components/topBar';
import Footer from '../components/footer';
import Breadcrumbs from '../components/breadcrumbs';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import ApiError from '../utils/apiError';

const ForgotPassword = (props) => {
    const [usernameReadOnly, setUsernameReadOnly] = useState(false);
    const [securityQuestion, setSecurityQuestion] = useState('');
    const [disableSendPass, setDisableSendPass] = useState(false);
    const [errors, setErrors] = useState({});

    const handleCancel = () => {
        window.location = allRoutes.root;
    };

    const showSuccessfulMessage = (message) => {
        NotificationManager.success(message, 'Success');
    };

    const showFailureMessage = (message) => {
        NotificationManager.error(message, 'Error');
    };

    const fetchSecurityQuestion = (e) => {
        e.preventDefault();
        const mobileNo = document.querySelector('[name="user_name"]').value;
        const mobileNoRegex = /^[6-9]\d{9}$/;
        if (!mobileNoRegex.test(mobileNo)) {
            setErrors({...errors, user_name: 'Mobile number is not valid'});
            return;
        }
        setErrors({...errors, user_name: null});
        const myHeaders = new Headers();
        myHeaders.append('API-Key', defaultApiKey);
        myHeaders.append('Content-Type', 'application/json');

        const raw = JSON.stringify({'user_name': mobileNo});

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
        };

        props.showLoader();
        fetch(forgotPasswordUrl + `?id=${currentMerchant}`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((responseBody) => {
                        throw new ApiError(response.status, response.statusText, responseBody.Status);
                    });
                }
                return response.json();
            })
            .then((result) => {
                props.hideLoader();
                setUsernameReadOnly(true);
                setSecurityQuestion(result[0].sec_ques);
            })
            .catch((error) => {
                props.hideLoader();
                // console.error(`statusCode : ${error.code} & statusMessage : ${error.message} & apiMessage : ${error.apiMessage}`);
                // props.apiErrorModalHandleShow(error.code, error.message, error.apiMessage);
                setErrors({...errors, user_name: error.apiMessage});
                showFailureMessage(error.apiMessage);
            });
    };

    const sendPassword = (e) => {
        e.preventDefault();
        const mobileNo = document.querySelector('[name="user_name"]').value;
        const mobileNoRegex = /^[6-9]\d{9}$/;
        if (!mobileNoRegex.test(mobileNo)) {
            setErrors({...errors, user_name: 'Mobile number is not valid'});
            return;
        }
        setErrors({...errors, user_name: null});

        const answer = document.querySelector('[name="answr"]').value;
        if (!answer) {
            setErrors({...errors, answr: 'Answer cannot be blanked'});
            return;
        }
        setErrors({...errors, answr: null});
        setDisableSendPass(true);

        const myHeaders = new Headers();
        myHeaders.append('API-Key', defaultApiKey);
        myHeaders.append('Content-Type', 'application/json');

        const raw = JSON.stringify({'user_name': mobileNo});

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
        };

        props.showLoader();
        fetch(sendPasswordUrl + answer + `?id=${currentMerchant}`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((responseBody) => {
                        throw new ApiError(response.status, response.statusText, responseBody.Status);
                    });
                }
                return response.json();
            })
            .then((result) => {
                props.hideLoader();
                showSuccessfulMessage(result.Status);
            })
            .catch((error) => {
                props.hideLoader();
                // console.error(`statusCode : ${error.code} & statusMessage : ${error.message} & apiMessage : ${error.apiMessage}`);
                // props.apiErrorModalHandleShow(error.code, error.message, error.apiMessage);
                setErrors({...errors, answr: error.apiMessage});
                showFailureMessage(error.apiMessage);
                setDisableSendPass(false);
            });
    };

    return (
        <>
            <TopBar noMenu />
            <Container fluid="md" className="p-4 mb-5">
                <Breadcrumbs title="Forgot Password" />
                <Form>
                    <Form.Row className="justify-content-md-center">
                        <Form.Group as={Col} md="6" controlId="user_name">
                            <Form.Label>Username</Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    type="text"
                                    placeholder="Mobile Number"
                                    aria-describedby="inputGroupPrepend"
                                    name="user_name"
                                    readOnly={usernameReadOnly}
                                />
                                <Form.Control.Feedback type="invalid" className="d-block">
                                    {errors.user_name}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    {securityQuestion ?
                        <>
                            <Form.Row className="justify-content-md-center">
                                <Form.Group as={Col} md="6" controlId="sec_ques">
                                    <Form.Label>Security Question</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Security Question"
                                        name="sec_ques"
                                        value={securityQuestion}
                                        readOnly
                                    />
                                    <Form.Control.Feedback type="invalid" className="d-block">
                                        {errors.sec_ques}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="justify-content-md-center">
                                <Form.Group as={Col} md="6" controlId="answr">
                                    <Form.Label>Answer</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Your Answer"
                                        name="answr"
                                    />
                                    <Form.Control.Feedback type="invalid" className="d-block">
                                        {errors.answr}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                        </> : ''
                    }
                    <Form.Row className="justify-content-md-center">
                        <Form.Group as={Col} md="6">
                            {!securityQuestion ?
                                <Button className="float-right ml-1" onClick={fetchSecurityQuestion}>Proceed</Button> :
                                <Button className="float-right ml-1"
                                    onClick={sendPassword}
                                    disabled={disableSendPass? true : undefined}>Submit</Button>
                            }
                            <Button className="float-right" onClick={handleCancel}>Back</Button>
                        </Form.Group>
                    </Form.Row>
                </Form>
                <NotificationContainer />
            </Container>
            <Footer />
        </>
    );
};

ForgotPassword.propTypes = {
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    apiErrorModalHandleShow: PropTypes.func.isRequired,
};

export default ForgotPassword;
