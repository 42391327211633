import React from 'react';
import {Container, Row, Col, Button, Form} from 'react-bootstrap';
import {Bar, Line} from 'react-chartjs-2';
import TopBar from '../../components/topBar';
import Footer from '../../components/footer';
import Breadcrumbs from '../../components/breadcrumbs';
import authManager from '../../utils/authManager';
import {devicesApiUrl, demandApiUrl, locationList, consumerByLocation} from '../../config';
import logManager from '../../utils/logManager';
import ApiError from '../../utils/apiError';
import Select from 'react-select';
import PropTypes from 'prop-types';
import 'chartjs-plugin-annotation';

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};
const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};

class ConsumptionDemandTrend extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            devices: [],
            locations: [],
            chart1: {
                devicesSelectedOption: '',
                locationSelectedOption: '',
                show: false,
                data: {},
                options: {},
            },
            chart2: {
                devicesSelectedOption: '',
                locationSelectedOption: '',
                show: false,
                data: {},
                options: {},
            },
        };

        // this.setXLine = this.setXLine.bind(this);
        this.getDevicesForSelectList = this.getDevicesForSelectList.bind(this);
        this.handleChart1DevicesFilterChange = this.handleChart1DevicesFilterChange.bind(this);
        this.handleChart1FilterSubmit = this.handleChart1FilterSubmit.bind(this);
        this.getLocationsForSelectList = this.getLocationsForSelectList.bind(this);
        this.handleChart1LocationFilterChange = this.handleChart1LocationFilterChange.bind(this);
        this.fetchConsumersByLocation = this.fetchConsumersByLocation.bind(this);
        this.getConsumersForSelectList = this.getConsumersForSelectList.bind(this);

        this.chart1Reference = null;
        this.chart2Reference = null;
    }

    fetchDevices() {
        this.props.showLoader();
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('API-Key', authManager.getApiKey());

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        return fetch(devicesApiUrl + authManager.getMerchantId(), requestOptions)
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((responseBody) => {
                        throw new ApiError(response.status, response.statusText, responseBody.Status);
                    });
                }
                return response.json();
            })
            .then((devices) => {
                logManager.info(devices);
                this.props.hideLoader();
                return Promise.resolve(devices);
            })
            .catch((error) => {
                this.props.hideLoader();
                logManager.error(`statusCode : ${error.code} & statusMessage : ${error.message} & apiMessage : ${error.apiMessage}`);
                this.props.apiErrorModalHandleShow(error.code, error.message, error.apiMessage);
                return Promise.reject(new Error(error.apiMessage));
            });
    }

    fetchConsumersByLocation(location) {
        this.props.showLoader();
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('API-Key', authManager.getApiKey());

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        // const location = this.state.chart1.locationSelectedOption;
        return fetch(consumerByLocation + location, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((responseBody) => {
                        throw new ApiError(response.status, response.statusText, responseBody.Status);
                    });
                }
                return response.json();
            })
            .then((devices) => {
                logManager.info(devices);
                this.props.hideLoader();
                return Promise.resolve(devices);
            })
            .catch((error) => {
                this.props.hideLoader();
                logManager.error(`statusCode : ${error.code} & statusMessage : ${error.message} & apiMessage : ${error.apiMessage}`);
                this.props.apiErrorModalHandleShow(error.code, error.message, error.apiMessage);
                return Promise.reject(new Error(error.apiMessage));
            });
    }

    fetchLocations() {
        this.props.showLoader();
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('API-Key', authManager.getApiKey());

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        return fetch(locationList, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((responseBody) => {
                        throw new ApiError(response.status, response.statusText, responseBody.Status);
                    });
                }
                return response.json();
            })
            .then((locations) => {
                logManager.info(locations);
                this.props.hideLoader();
                return Promise.resolve(locations);
            })
            .catch((error) => {
                this.props.hideLoader();
                logManager.error(`statusCode : ${error.code} & statusMessage : ${error.message} & apiMessage : ${error.apiMessage}`);
                this.props.apiErrorModalHandleShow(error.code, error.message, error.apiMessage);
                return Promise.reject(new Error(error.apiMessage));
            });
    }

    componentDidMount() {
        this.fetchLocations()
            .then((locations) => {
                this.setState({locations});
            })
            .catch((error) => {
                this.setState({locations: []});
            });
    }

    getDevicesForSelectList() {
        const options = {};

        this.state.devices.forEach((element) => {
            if (options[element.manufact_name] !== undefined) {
                options[element.manufact_name].options.push({value: element.device_id, label: element.device_id});
            } else {
                options[element.manufact_name] = {};
                options[element.manufact_name].label = element.manufact_name;
                options[element.manufact_name].options = [];
                options[element.manufact_name].options.push({value: element.device_id, label: element.device_id});
            }
        });

        return Object.values(options);
    }

    getConsumersForSelectList() {
        return this.state.devices.map((element) => {
            return {value: element.device_id, label: element.consumer_id + ' : ' + element.consumer_name};
        });
    }

    getLocationsForSelectList() {
        return this.state.locations.map((element) => {
            return {value: element.location, label: element.location};
        });
    }

    formatGroupLabel(data) {
        return (<div style={groupStyles}>
            <span>{data.label}</span>
            <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>);
    }

    handleChart1DevicesFilterChange(devicesSelectedOption) {
        if (devicesSelectedOption) {
            this.setState({chart1: {devicesSelectedOption: devicesSelectedOption.value}});
        } else {
            this.setState({chart1: {devicesSelectedOption: ''}});
        }
    }

    handleChart1LocationFilterChange(locationSelectedOption) {
        if (locationSelectedOption) {
            this.setState({chart1: {locationSelectedOption: locationSelectedOption.value}});
            this.fetchConsumersByLocation(locationSelectedOption.value)
                .then((devices) => {
                    this.setState({devices});
                })
                .catch((error) => {
                    this.setState({devices: []});
                });
        } else {
            this.setState({chart1: {locationSelectedOption: ''}});
        }
    }

    handleChart1FilterSubmit(e) {
        e.preventDefault();
        const selectedDevice = this.state.chart1.devicesSelectedOption;
        logManager.info(selectedDevice);
        if (selectedDevice) {
            this.renderChart1Data(selectedDevice);
        }
    }

    fetchChart1Data(device) {
        this.props.showLoader();
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('API-Key', authManager.getApiKey());

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        return fetch(demandApiUrl + device, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((responseBody) => {
                        throw new ApiError(response.status, response.statusText, responseBody.Status);
                    });
                }
                return response.json();
            })
            .then((chartData) => {
                logManager.info(chartData);
                this.props.hideLoader();
                return Promise.resolve(chartData);
            })
            .catch((error) => {
                this.props.hideLoader();
                logManager.error(`statusCode : ${error.code} & statusMessage : ${error.message} & apiMessage : ${error.apiMessage}`);
                this.props.apiErrorModalHandleShow(error.code, error.message, error.apiMessage);
                return Promise.reject(new Error(error.apiMessage));
            });
    }

    renderChart1Data(device) {
        this.fetchChart1Data(device)
            .then((chartData) => {
                this.renderChart2Data(chartData);
                const labels = [];
                const barData = [];
                const lineData = [];
                chartData.forEach((element) => {
                    labels.push(element.bill_mnth);
                    barData.push(element.consumption);
                    lineData.push(element.max_demand);
                });

                const data = {
                    datasets: [{
                        label: 'Maximum Demand',
                        type: 'line',
                        data: lineData,
                        fill: false,
                        borderColor: '#F17767',
                        backgroundColor: '#F17767',
                        pointBorderColor: '#F17767',
                        pointBackgroundColor: '#F17767',
                        pointHoverBackgroundColor: '#F17767',
                        pointHoverBorderColor: '#F17767',
                        yAxisID: 'y-axis-2',
                    }, {
                        type: 'bar',
                        label: 'Consumption',
                        data: barData,
                        fill: false,
                        backgroundColor: '#3A6391',
                        borderColor: '#3A6391',
                        hoverBackgroundColor: '#3A6391',
                        hoverBorderColor: '#3A6391',
                        yAxisID: 'y-axis-1',
                    }],
                };

                const options = {
                    responsive: true,
                    title: {
                        display: true,
                        text: 'Consumption trend',
                        fontSize: 18,
                    },
                    tooltips: {
                        mode: 'label',
                    },
                    legend: {
                        position: 'top',
                        align: 'start',
                    },
                    elements: {
                        line: {
                            fill: false,
                        },
                    },
                    scales: {
                        xAxes: [
                            {
                                display: true,
                                gridLines: {
                                    display: false,
                                },

                                labels: labels,
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Months',
                                },
                            },
                        ],
                        yAxes: [
                            {
                                type: 'linear',
                                display: true,
                                position: 'left',
                                id: 'y-axis-1',
                                gridLines: {
                                    display: false,
                                },
                                labels: {
                                    show: true,
                                },
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Consumption',
                                },
                            },
                            {
                                type: 'linear',
                                display: true,
                                position: 'right',
                                id: 'y-axis-2',
                                gridLines: {
                                    display: false,
                                },
                                labels: {
                                    show: true,
                                },
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Maximum Demand (kW)',
                                },
                            },
                        ],
                    },
                };

                const chart1 = this.state.chart1;
                chart1.show = true;
                chart1.data = data;
                chart1.options = options;
                this.setState({chart1});
            })
            .catch((error) => {
            });
    }

    async renderChart2Data(chartData) {
        const labels = [];
        const lineData = [];
        let annotationLine = 0;
        chartData.forEach((element) => {
            labels.push(element.bill_mnth);
            lineData.push(element.max_demand);
            annotationLine = element.con_demand;
        });
        const data = {
            labels: labels,
            datasets: [{
                label: 'Maximum Demand',
                yAxisID: 'A',
                data: lineData,
                fill: false,
                borderColor: '#3A6391',
                backgroundColor: '#3A6391',
                pointBorderColor: '#3A6391',
                pointBackgroundColor: '#3A6391',
                pointRadius: 5,
                pointHoverRadius: 15,
                pointHitRadius: 30,
                pointBorderWidth: 2,
            }],
        };

        const options = {
            legend: {
                position: 'top',
                align: 'start',
            },
            scales: {
                xAxes: [
                    {
                        display: true,
                        gridLines: {
                            display: false,
                        },

                        labels: labels,
                        scaleLabel: {
                            display: true,
                            labelString: 'Months',
                        },
                    },
                ],
                yAxes: [{
                    id: 'A',
                    type: 'linear',
                    position: 'left',
                    gridLines: {
                        display: false,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'kW',
                    },
                }],
            },
            title: {
                display: true,
                text: 'Demand trend',
                fontSize: 18,
            },
            annotation: {
                annotations: [{
                    type: 'line',
                    mode: 'horizontal',
                    scaleID: 'A',
                    value: annotationLine,
                    borderColor: '#F17767',
                    borderWidth: 2,
                    label: {
                        enabled: true,
                        content: 'Contract Demand',
                    },
                }],
            },
        };
        const chart2 = this.state.chart2;
        chart2.show = true;
        chart2.data = data;
        chart2.options = options;
        this.setState({chart2});
    }

    render() {
        return (
            <>
                <TopBar />
                <Container fluid className="shadow p-4 bg-white rounded">
                    <Breadcrumbs title="Consumption" />
                    <Row className="mb-2">
                        <Col md="12">
                            <Form>
                                <Form.Row className="mb-1">
                                    <Col md="3">
                                        <Select id="chart1LocationFilter"
                                            options={this.getLocationsForSelectList()}
                                            isClearable
                                            onChange={this.handleChart1LocationFilterChange}
                                        />
                                    </Col>
                                    <Col md="3">
                                        <Select id="chart1DevicesFilter"
                                            options={this.getConsumersForSelectList()}
                                            isClearable
                                            onChange={this.handleChart1DevicesFilterChange}
                                            formatGroupLabel={this.formatGroupLabel}
                                        />
                                    </Col>
                                    <Col md="3">
                                        <Button className="float-lg-left" type="submit" onClick={this.handleChart1FilterSubmit}>Filter</Button>
                                    </Col>
                                </Form.Row>
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6" style={{minHeight: '300px'}} className="shadow p-3 mb-2 bg-white rounded">
                            {this.state.chart1.show ?
                                <Bar
                                    data={this.state.chart1.data}
                                    options={this.state.chart1.options}
                                    ref={(reference) => this.chart1Reference = reference}
                                /> : ''
                            }
                        </Col>
                        <Col md="6" style={{minHeight: '300px'}} className="shadow p-3 mb-2 bg-white rounded">
                            {this.state.chart2.show ?
                                <Line
                                    data={this.state.chart2.data}
                                    options={this.state.chart2.options}
                                    ref={(reference) => this.chart2Reference = reference}
                                /> : ''
                            }
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </>
        );
    }
}

ConsumptionDemandTrend.propTypes = {
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    apiErrorModalHandleShow: PropTypes.func.isRequired,
};

export default ConsumptionDemandTrend;
