import React from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {textFilter, Comparator} from 'react-bootstrap-table2-filter';
import DatePicker from 'react-datepicker';
import {Container, Row, Col, Button, Form} from 'react-bootstrap';
import {CSVLink} from 'react-csv';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDownload} from '@fortawesome/free-solid-svg-icons';
import {format} from 'date-fns';
import TopBar from '../../components/topBar';
import Footer from '../../components/footer';
import Breadcrumbs from '../../components/breadcrumbs';
import logManager from '../../utils/logManager';
import ApiError from '../../utils/apiError';
import authManager from '../../utils/authManager';
import {billingUrl} from '../../config';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

class Billing extends React.Component {
    constructor(props) {
        super();
        this.props = props;
        this.state = {
            selectedMonth: new Date(),
            data: [],
            billingMonth: null,
            billingPeriod: null,
        };

        this.setSelectedMonth = this.setSelectedMonth.bind(this);
        this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
    }

    setSelectedMonth(date) {
        this.setState({selectedMonth: date});
    }

    handleFilterSubmit(e) {
        e.preventDefault();
        if (!this.state.selectedMonth) {
            return false;
        }

        const monthYear = format(this.state.selectedMonth, 'MMM-yy');
        logManager.info(monthYear);

        this.fetchBills(monthYear)
            .then((data) => {
                const tempData = {};
                tempData.billingMonth = data.billmnth;
                tempData.billingPeriod = data.period;
                tempData.data = Object.values(data.billing);
                this.setState({...tempData});
            })
            .catch((error) => {

            });
    }

    fetchBills(monthYear) {
        this.props.showLoader();
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('API-Key', authManager.getApiKey());

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        return fetch(billingUrl + monthYear, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((responseBody) => {
                        throw new ApiError(response.status, response.statusText, responseBody.Status);
                    });
                }
                return response.json();
            })
            .then((data) => {
                logManager.info(data);
                this.props.hideLoader();
                return Promise.resolve(data);
            })
            .catch((error) => {
                this.props.hideLoader();
                logManager.error(`statusCode : ${error.code} & statusMessage : ${error.message} & apiMessage : ${error.apiMessage}`);
                this.props.apiErrorModalHandleShow(error.code, error.message, error.apiMessage);
                return Promise.reject(new Error(error.apiMessage));
            });
    }

    getBillingTableColumns() {
        const defaultFilter = textFilter({
            placeholder: '',
            className: '',
            defaultValue: '',
            comparator: Comparator.LIKE,
            caseSensitive: false,
            style: {},
            delay: 500,
            id: 'id',
        });
        const columns = [
            {
                dataField: 'route_id',
                text: 'route_id',
            },
            {
                dataField: 'con_id',
                text: 'con_id',
                filter: defaultFilter,
            },
            {
                dataField: 'con_nm',
                text: 'name',
                filter: defaultFilter,
            },
            {
                dataField: 'dev_id',
                text: 'meter_no',
                filter: defaultFilter,
            },
            {
                dataField: 'categry',
                text: 'category',
            },
            {
                dataField: 'tariff',
                text: 'tariff',
            },
            {
                dataField: 'con_lod',
                text: 'con_lod',
            },
            {
                dataField: 'con_unt',
                text: 'con_unt',
            },
            {
                dataField: 'old_rdng',
                text: 'old_rdng',
            },
            {
                dataField: 'new_rdng',
                text: 'new_rdng',
            },
            {
                dataField: 'repl_rdng',
                text: 'repl_rdng',
            },
            {
                dataField: 'repl_rd_dt',
                text: 'repl_rd_dt',
            },
            {
                dataField: 'unts',
                text: 'unts',
            },
            {
                dataField: 'no_dys',
                text: 'no_dys',
            },
        ];
        return columns;
    }

    render() {
        return (
            <>
                <TopBar />
                <Container fluid className="p-4 bg-white">
                    <Breadcrumbs title="Billing" />
                    <Row className="mb-2">
                        <Col md="12">
                            <Form>
                                <Form.Row className="mb-1">
                                    <Col md="2" className="">
                                        <DatePicker
                                            className="form-control"
                                            selected={this.state.selectedMonth}
                                            onChange={this.setSelectedMonth}
                                            disabled={false}
                                            dateFormat="MMM-yy"
                                            showMonthYearPicker
                                        />
                                    </Col>
                                    <Col md="3">
                                        <Button
                                            className="float-lg-left"
                                            type="submit"
                                            onClick={this.handleFilterSubmit}
                                            disabled={(
                                                !this.state.selectedMonth
                                            )? true : undefined}
                                        >Fetch</Button>
                                    </Col>
                                </Form.Row>
                            </Form>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        {this.state.data.length ?
                            <>
                                <Col md="12">
                                    <CSVLink
                                        data={this.state.data ? this.state.data : ''}
                                        filename={'billing.csv'}
                                        className="btn btn-primary btn-sm float-right"
                                        target="_blank"
                                    >
                                        <FontAwesomeIcon icon={faDownload} />
                                    </CSVLink>
                                </Col>
                                <Col md="12">
                                    <BootstrapTable
                                        keyField='slno'
                                        data={this.state.data}
                                        columns={this.getBillingTableColumns()}
                                        filter={filterFactory()}
                                        bootstrap4
                                        striped
                                        hover
                                        condensed
                                        classes="bootstrap-striped-table2"
                                    />
                                    <b className="float-right">Billing Month: {this.state.billingMonth}</b>
                                    <b className="float-left">Billing Period: {this.state.billingPeriod}</b>
                                </Col>
                            </> : ''
                        }
                    </Row>
                </Container>
                <Footer />
            </>
        );
    }
}

Billing.propTypes = {
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    apiErrorModalHandleShow: PropTypes.func.isRequired,
};

export default Billing;
