import React, {Component} from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import Loader from '../components/loader';

import ApiErrorModal from '../components/apiErrorModal';

import Login from '../pages/login';
import Signup from '../pages/signup';
import Logout from '../pages/logout';
import NotFound404 from '../pages/notFound404';
import Home from '../pages/home';
import ConsumptionDemandTrend from '../pages/reports/consumptionDemandTrend';
import Meters from '../pages/masters/meters';
import Consumers from '../pages/masters/consumers';
import authManager from '../utils/authManager';
import Profile from '../pages/profile';
import Polling from '../pages/meter-management/polling';
import LoadSurvey from '../pages/reports/loadSurvey';
import Instantaneous from '../pages/reports/instantaneous';
import Events from '../pages/reports/events';
import ForgotPassword from '../pages/forgot-password';
import Billing from '../pages/meter-management/billing';
import {allRoutes, currentMerchant} from './../config';
import {validateMerchant} from '../utils/commonFunctions';
import MerchantInput from './../components/merchantInput';
import Reading from '../pages/reports/reading';
import ReadingParam from '../pages/reports/readingParam';
import HesStatus from '../pages/reports/hesStatus';


class App extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            apiErrorModalShow: false,
            apiErrorModalShowLogin: false,
            apiError: {
                errorCode: '',
                errorMessage: '',
                apiErrorMessage: '',
            },
            invalidMerchant: false,
        };

        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
        this.apiErrorModalHandleClose = this.apiErrorModalHandleClose.bind(this);
        this.apiErrorModalHandleShow = this.apiErrorModalHandleShow.bind(this);
        this.apiErrorModalHandleRedirectToLogin = this.apiErrorModalHandleRedirectToLogin.bind(this);
        this.handleUnload = this.handleUnload.bind(this);
    }

    showLoader() {
        this.setState({isLoading: true});
    }

    hideLoader() {
        this.setState({isLoading: false});
    }

    apiErrorModalHandleClose() {
        this.setState({
            apiErrorModalShow: false,
            apiError: {
                errorCode: '',
                errorMessage: '',
                apiErrorMessage: '',
            },
        });
    }

    apiErrorModalHandleRedirectToLogin() {
        this.setState({
            apiErrorModalShow: false,
            apiErrorModalShowLogin: false,
            apiError: {
                errorCode: '',
                errorMessage: '',
                apiErrorMessage: '',
            },
        });

        window.location = '/';
    }

    apiErrorModalHandleShow(errorCode, errorMessage, apiErrorMessage) {
        this.setState({
            apiErrorModalShow: true,
            apiError: {errorCode, errorMessage, apiErrorMessage},
        });

        if (apiErrorMessage) {
            if (apiErrorMessage.toLowerCase().includes('session expired')) {
                authManager.unsetUser();
                this.setState({apiErrorModalShowLogin: true});
            }
        }
    }

    componentDidMount() {
        window.addEventListener('beforeunload', this.handleUnload);
        const merchantValidation = validateMerchant(currentMerchant);
        if (merchantValidation === 'noMerchant') {
            this.setState({invalidMerchant: true});
        } else if (merchantValidation === 'invalidMerchant') {
            this.setState({invalidMerchant: true});
        } else if (merchantValidation === 'validMerchant') {
            this.setState({invalidMerchant: false});
        }
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleUnload);
    }

    handleUnload(e) {
        const message = 'Do you really want to close this tab?';
        (e || window.event).returnValue = message; // Gecko + IE
        return message;
    }

    render() {
        return (
            <>
                {this.state.isLoading ? <Loader /> : '' }
                {
                    this.state.invalidMerchant?
                        <MerchantInput show={this.state.invalidMerchant} handleUnload={this.handleUnload}/> :
                        ''
                }
                <Switch>
                    <Route exact path={allRoutes.root}>
                        {authManager.getUser() === null ?
                            <Login showLoader={this.showLoader} hideLoader={this.hideLoader}
                                apiErrorModalHandleShow={this.apiErrorModalHandleShow} /> :
                            <Redirect to={allRoutes.home} />}
                    </Route>
                    <Route exact path={allRoutes.signUp}>
                        {authManager.getUser() === null ?
                            <Signup showLoader={this.showLoader} hideLoader={this.hideLoader}
                                apiErrorModalHandleShow={this.apiErrorModalHandleShow} /> :
                            <Redirect to={allRoutes.home} />}
                    </Route>
                    <Route exact path={allRoutes.forgotPassword}>
                        {authManager.getUser() === null ?
                            <ForgotPassword showLoader={this.showLoader} hideLoader={this.hideLoader}
                                apiErrorModalHandleShow={this.apiErrorModalHandleShow} /> :
                            <Redirect to={allRoutes.home} />}
                    </Route>
                    <Route exact path={allRoutes.home}>
                        {authManager.getUser() !== null ?
                            <Home showLoader={this.showLoader} hideLoader={this.hideLoader}
                                apiErrorModalHandleShow={this.apiErrorModalHandleShow} /> :
                            <Redirect to={allRoutes.root} />}
                    </Route>
                    <Route exact path={allRoutes.entryMeter}>
                        {authManager.getUser() !== null ?
                            <Meters showLoader={this.showLoader} hideLoader={this.hideLoader}
                                apiErrorModalHandleShow={this.apiErrorModalHandleShow} /> :
                            <Redirect to={allRoutes.root} />}
                    </Route>
                    <Route exact path={allRoutes.entryConsumer}>
                        {authManager.getUser() !== null ?
                            <Consumers showLoader={this.showLoader} hideLoader={this.hideLoader}
                                apiErrorModalHandleShow={this.apiErrorModalHandleShow} /> :
                            <Redirect to={allRoutes.root} />}
                    </Route>
                    <Route exact path={allRoutes.reportConsumerDemand}>
                        {authManager.getUser() !== null ?
                            <ConsumptionDemandTrend showLoader={this.showLoader} hideLoader={this.hideLoader}
                                apiErrorModalHandleShow={this.apiErrorModalHandleShow} /> :
                            <Redirect to={allRoutes.root} />}
                    </Route>
                    <Route exact path={allRoutes.reportLoadSurvey}>
                        {authManager.getUser() !== null ?
                            <LoadSurvey showLoader={this.showLoader} hideLoader={this.hideLoader}
                                apiErrorModalHandleShow={this.apiErrorModalHandleShow} /> :
                            <Redirect to={allRoutes.root} />}
                    </Route>
                    <Route exact path={allRoutes.reportInstantaneous}>
                        {authManager.getUser() !== null ?
                            <Instantaneous showLoader={this.showLoader} hideLoader={this.hideLoader}
                                apiErrorModalHandleShow={this.apiErrorModalHandleShow} /> :
                            <Redirect to={allRoutes.root} />}
                    </Route>
                    <Route exact path={allRoutes.reportReading}>
                        {authManager.getUser() !== null ?
                            <Reading showLoader={this.showLoader} hideLoader={this.hideLoader}
                                apiErrorModalHandleShow={this.apiErrorModalHandleShow} /> :
                            <Redirect to={allRoutes.root} />}
                    </Route>
                    <Route exact path={allRoutes.reportReadingParam}>
                        {authManager.getUser() !== null ?
                            <ReadingParam showLoader={this.showLoader} hideLoader={this.hideLoader}
                                apiErrorModalHandleShow={this.apiErrorModalHandleShow} /> :
                            <Redirect to={allRoutes.root} />}
                    </Route>
                    <Route exact path={allRoutes.hesStatus}>
                        {authManager.getUser() !== null ?
                            <HesStatus showLoader={this.showLoader} hideLoader={this.hideLoader}
                                apiErrorModalHandleShow={this.apiErrorModalHandleShow} /> :
                            <Redirect to={allRoutes.root} />}
                    </Route>
                    <Route exact path={allRoutes.reportEvent}>
                        {authManager.getUser() !== null ?
                            <Events showLoader={this.showLoader} hideLoader={this.hideLoader}
                                apiErrorModalHandleShow={this.apiErrorModalHandleShow} /> :
                            <Redirect to={allRoutes.root} />}
                    </Route>
                    <Route exact path={allRoutes.polling}>
                        {authManager.getUser() !== null ?
                            <Polling showLoader={this.showLoader} hideLoader={this.hideLoader}
                                apiErrorModalHandleShow={this.apiErrorModalHandleShow} /> :
                            <Redirect to={allRoutes.root} />}
                    </Route>
                    <Route exact path={allRoutes.billing}>
                        {authManager.getUser() !== null ?
                            <Billing showLoader={this.showLoader} hideLoader={this.hideLoader}
                                apiErrorModalHandleShow={this.apiErrorModalHandleShow} /> :
                            <Redirect to={allRoutes.root} />}
                    </Route>
                    <Route exact path={allRoutes.profile}>
                        {authManager.getUser() !== null ?
                            <Profile showLoader={this.showLoader} hideLoader={this.hideLoader}
                                apiErrorModalHandleShow={this.apiErrorModalHandleShow} /> :
                            <Redirect to={allRoutes.root} />}
                    </Route>
                    <Route exact path={allRoutes.logOut} component={Logout}>
                    </Route>
                    <Route path="*">
                        <NotFound404 />
                    </Route>
                </Switch>
                <ApiErrorModal error={{
                    apiErrorModalShow: this.state.apiErrorModalShow,
                    apiErrorModalShowLogin: this.state.apiErrorModalShowLogin,
                    apiError: this.state.apiError,
                    apiErrorModalHandleClose: this.apiErrorModalHandleClose,
                    apiErrorModalHandleRedirectToLogin: this.apiErrorModalHandleRedirectToLogin,
                }}/>
            </>
        );
    }
}

export default App;
