import React from 'react';
import loaderImage from '../assets/loader1.gif';
import '../assets/loader.css';

const style = {
    // display: 'block',
    // position: 'fixed',
    // zIndex: 100,
    backgroundImage: `url(${loaderImage})`,
    // backgroundColor: '#666',
    // opacity: 0.4,
    // backgroundRepeat :'no-repeat',
    // backgroundPosition : 'center',
    // left: 0,
    // bottom: 0,
    // right: 0,
    // top: 0,
};

const Loader = () => {
    return (
        <div id="divLoading" style={style}>
        </div>
    );
};

export default Loader;
