import {
    validMerchants,
} from './../config';
export const validateMerchant = (merchant) => {
    if (!merchant) {
        return 'noMerchant';
    }
    for (let i = 0; i < validMerchants.length; i++) {
        if (validMerchants[i].id === merchant) {
            return 'validMerchant';
        }
    }
    return 'invalidMerchant';
};
