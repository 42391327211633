import React, {useState, useEffect} from 'react';
import {Form, Col, Button} from 'react-bootstrap';
import * as yup from 'yup';
import {useForm} from 'react-hook-form';
import {RHFInput} from 'react-hook-form-input';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import {addDays, format} from 'date-fns';
import PropTypes from 'prop-types';
import {securityQuestions} from '../config';

const schema = yup.object().shape({
    mdm_user: yup.string().required('Name is required'),
    email_id: yup.string().email().required('Email ID is required'),
    location: yup.string().required('Location is required'),
    pin_code: yup.string().required('Pincode is required'),
    secret_ques: yup.object().shape({
        value: yup.string().required('Security question is required'),
        label: yup.string(),
    }),
    answr: yup.string().required('Security Answer is required'),
    connect_date: yup.date().nullable(),
    access_type: yup.string().required('Access Type is required'),
    status_1: yup.string().required('Status is required'),
});

const ProfileManagement = (props) => {
    const {register, handleSubmit, errors, setValue, reset} = useForm({
        validationSchema: schema,
    });

    const [leaveDate, setLeaveDate] = useState(null);
    const [disableInput, setDisableInput] = useState(true);

    useEffect(() => {
        if (props.profile.leave_enddt) {
            setLeaveDate(new Date(props.profile.leave_enddt));
        }
    }, [props.profile.leave_enddt]);

    const onSubmit = (data) => {
        const tempData = {};
        for (const key in data) {
            if (data[key] instanceof Date) {
                tempData[key] = format(data[key], 'yyyy-MM-dd');
            } else if (typeof data[key] === 'object' && data[key] !== null) {
                if (data[key].value !== undefined && data[key].value !== null) {
                    tempData[key] = data[key].value;
                } else {
                    tempData[key] = '';
                }
            } else {
                if (data[key] === null) {
                    // tempData[key] = '';
                } else {
                    tempData[key] = data[key];
                }
            }
        }
        props.updateProfile(tempData);
    };

    const handleEdit = () => {
        setDisableInput(false);
    };

    const handleCancel = () => {
        reset(props.profile);
        setDisableInput(true);
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)} className="">
            <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md="6" controlId="user_name">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Mobile Number"
                        name="user_name"
                        ref={register}
                        defaultValue={props.profile.user_name}
                        readOnly
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.user_name && errors.user_name.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="mdm_user">
                    <Form.Label>Your Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Your Name"
                        name="mdm_user"
                        ref={register}
                        defaultValue={props.profile.mdm_user}
                        readOnly={disableInput ? true : undefined}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.mdm_user && errors.mdm_user.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md="6" controlId="merchant_id">
                    <Form.Label>Merchant Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Merchant Name"
                        name="merchant_id"
                        // ref={register}
                        defaultValue={props.profile.company}
                        readOnly
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {/* {errors.merchant_id && errors.merchant_id.message} */}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="location">
                    <Form.Label>Location</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Location"
                        name="location"
                        ref={register}
                        defaultValue={props.profile.location}
                        readOnly={disableInput ? true : undefined}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.location && errors.location.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md="6" controlId="pin_code">
                    <Form.Label>Pincode</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Pincode"
                        name="pin_code"
                        ref={register}
                        defaultValue={props.profile.pin_code}
                        readOnly={disableInput ? true : undefined}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.pin_code && errors.pin_code.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="email_id">
                    <Form.Label>Email ID</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Email ID"
                        name="email_id"
                        ref={register}
                        defaultValue={props.profile.email_id}
                        readOnly={disableInput ? true : undefined}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.email_id && errors.email_id.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md="6" controlId="secret_ques">
                    <Form.Label>Security Question</Form.Label>
                    <RHFInput
                        as={disableInput ?
                            <Form.Control
                                type="text"
                                readOnly
                            /> :
                            <Select options={securityQuestions} />
                        }
                        value={disableInput ?
                            props.profile.secret_ques :
                            {
                                value: props.profile.secret_ques,
                                label: props.profile.secret_ques,
                            }
                        }
                        name="secret_ques"
                        register={register}
                        setValue={(...selected) => {
                            setValue(selected[0], selected[1], {shouldValidate: true});
                        }}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.secret_ques && errors.secret_ques.value && errors.secret_ques.value.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="answr">
                    <Form.Label>Security Question Answer</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Security Question Answer"
                        name="answr"
                        ref={register}
                        defaultValue={props.profile.answr}
                        readOnly={disableInput ? true : undefined}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.answr && errors.answr.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md="6" controlId="leave_enddt">
                    <Form.Label>Leave End Date</Form.Label><br></br>
                    <RHFInput
                        as={disableInput ?
                            <Form.Control
                                type="text"
                                readOnly
                            /> :
                            <DatePicker
                                className="form-control"
                                isClearable
                                selected={leaveDate}
                                dateFormat="yyyy-MM-dd"
                                minDate={addDays(new Date(), 1)}
                                showDisabledMonthNavigation
                            />
                        }
                        value={leaveDate? format(leaveDate, 'dd-MM-yyyy') : ''}
                        name="leave_enddt"
                        register={register}
                        setValue={(...selected) => {
                            setLeaveDate(selected[1]);
                            setValue(selected[0], selected[1], {shouldValidate: true});
                        }}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.leave_enddt && errors.leave_enddt.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="status_1">
                    <Form.Label>Status</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Status"
                        name="status_1"
                        ref={register}
                        defaultValue={props.profile.status}
                        readOnly
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.status_1 && errors.status_1.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <Form.Row className="">
                <Form.Group as={Col} md="6" controlId="access_type">
                    <Form.Label>Access Type</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Access Type"
                        name="access_type"
                        ref={register}
                        defaultValue={props.profile.access_type}
                        readOnly
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.access_type && errors.access_type.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <Form.Row className="justify-content-md-center mb-2">
                <Form.Group as={Col} md="12">
                    {disableInput ?
                        <Button className="float-right ml-1" onClick={handleEdit}>Edit Profile</Button> :
                        <>
                            <Button type="submit" className="float-right ml-1">Save Profile</Button>
                            <Button className="float-right" onClick={handleCancel}>Reset</Button>
                        </>
                    }
                </Form.Group>
            </Form.Row>
        </Form>
    );
};

ProfileManagement.propTypes = {
    profile: PropTypes.object.isRequired,
    updateProfile: PropTypes.func.isRequired,
};

export default ProfileManagement;
