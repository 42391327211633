import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/index.css';
import App from './router';


// import App1 from './App1';
import * as serviceWorker from './serviceWorker';

// Add these lines:
if (process.env.NODE_ENV !== 'production') {
    localStorage.setItem('debug', 'mdm-lite-app:*');
}

ReactDOM.render(
    // <React.StrictMode>
    <Router>
        <App />
    </Router>,
    // </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
