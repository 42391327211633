import React from 'react';
import {Button} from 'react-bootstrap';
import PropTypes from 'prop-types';

class ActionButtons extends React.Component {
    constructor(props) {
        super();
        this.props = props;

        this.onEditClick = this.onEditClick.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
    }

    onEditClick() {
        this.props.editOnClick(this.props.data.dataIndex);
    }

    onDeleteClick() {
        this.props.deleteOnClick(this.props.data.dataIndex);
    }

    render() {
        return (
            <>
                {this.props.showEdit !== undefined?
                    (<Button onClick={this.onEditClick} variant="primary" size="sm"><i className="fas fa-edit"></i></Button>) : ''}
                {this.props.showDelete !== undefined ?
                    (<Button onClick={this.onDeleteClick} variant="danger" size="sm"><i className="fas fa-trash"></i></Button>) : ''}
            </>
        );
    }
}

ActionButtons.propTypes = {
    editOnClick: PropTypes.func.isRequired,
    deleteOnClick: PropTypes.func.isRequired,
    showEdit: PropTypes.any,
    showDelete: PropTypes.any,
    data: PropTypes.shape({
        dataIndex: PropTypes.number.isRequired,
    }),
};

export default ActionButtons;
