import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {Form, Col, Button, InputGroup, Container} from 'react-bootstrap';
import * as yup from 'yup';
import Select from 'react-select';
import {RHFInput} from 'react-hook-form-input';
import {defaultApiKey, signupApiUrl, createOtpUrl, verifyOtpUrl, securityQuestions, currentMerchant, allRoutes} from '../config';
import PropTypes from 'prop-types';
import TopBar from '../components/topBar';
import Footer from '../components/footer';
import Breadcrumbs from '../components/breadcrumbs';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import ApiError from '../utils/apiError';
import logManager from '../utils/logManager';

const schema = yup.object().shape({
    user_name: yup.string().required('Mobile No is required')
        .matches(/^[6-9]\d{9}$/, 'Please enter valid number.'),
    mdm_user: yup.string().required('Name is required'),
    email_id: yup.string().required('Email ID is required'),
    passwd: yup.string().required('Password is required')
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .matches(/[a-z]/, 'Password must have atleast one Lower case character.')
        .matches(/[A-Z]/, 'Password must have atleast one Upercase case character.')
        .matches(/\d/, 'Password must have one atleast Number.')
        .matches(/[\W_]/, 'Password must have atleast one Symbol.'),
    cnfpasswd: yup.string().oneOf([yup.ref('passwd'), null], 'Passwords must match'),
    remarks: yup.object().shape({
        value: yup.string().required('Company is required'),
        label: yup.string(),
    }),
    location: yup.string().required('Location is required'),
    pin_code: yup.string().required('Pincode is required'),
    secret_ques: yup.object().shape({
        value: yup.string().required('Security question is required'),
        label: yup.string(),
    }),
    answr: yup.string().required('Security Answer is required'),
    access_type: yup.object().shape({
        value: yup.string().required('Access Type is required'),
        label: yup.string(),
    }),
    status_1: yup.object().shape({
        value: yup.string().required('Status is required'),
        label: yup.string(),
    }),
});

const Signup = (props) => {
    const [otpVerified, setOTPVerified] = useState(false);
    const [sentOTP, setSentOTP] = useState(false);
    const [usernameReadOnly, setUsernameReadOnly] = useState(false);
    const {register, handleSubmit, errors, getValues, setValue, reset} = useForm({
        validationSchema: schema,
    });

    const onSubmit = async (data) => {
        const tempData = {};
        for (const key in data) {
            if (typeof data[key] === 'object') {
                if (data[key].value !== undefined) {
                    tempData[key] = data[key].value;
                } else {
                    tempData[key] = '';
                }
            } else {
                tempData[key] = data[key];
            }
        }

        await addUser(tempData);
    };

    const addUser = (data) => {
        props.showLoader();
        const myHeaders = new Headers();
        myHeaders.append('API-Key', defaultApiKey);
        myHeaders.append('Content-Type', 'application/json');

        const raw = JSON.stringify(data);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
        };

        fetch(signupApiUrl + `?id=${currentMerchant}`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((responseBody) => {
                        throw new ApiError(response.status, response.statusText, responseBody.Status);
                    });
                }
                return response.json();
            })
            .then((result) => {
                logManager.info(result);
                props.hideLoader();
                showSuccessfulMessage(result.Status);
                reset();
            })
            .catch((error) => {
                props.hideLoader();
                logManager.error(`statusCode : ${error.code} & statusMessage : ${error.message} & apiMessage : ${error.apiMessage}`);
                props.apiErrorModalHandleShow(error.code, error.message, error.apiMessage);
            });
    };

    const showSuccessfulMessage = (message) => {
        NotificationManager.success(message, 'Success');
    };

    const showFailureMessage = (message) => {
        NotificationManager.error(message, 'Error');
    };

    const preventPaste = (e) => {
        e.preventDefault();
        return false;
    };

    const handleOTPSend = (e) => {
        e.preventDefault();
        const mobileNo = getValues('user_name');
        const mobileNoRegex = /^[6-9]\d{9}$/;
        if (mobileNoRegex.test(mobileNo)) {
            const myHeaders = new Headers();
            myHeaders.append('API-Key', defaultApiKey);
            myHeaders.append('Content-Type', 'application/json');

            const raw = JSON.stringify({'user_name': mobileNo});

            const requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow',
            };

            props.showLoader();
            fetch(createOtpUrl + `?id=${currentMerchant}`, requestOptions)
                .then((response) => {
                    if (!response.ok) {
                        return response.json().then((responseBody) => {
                            throw new ApiError(response.status, response.statusText, responseBody.Status);
                        });
                    }
                    return response.json();
                })
                .then((result) => {
                    props.hideLoader();
                    setSentOTP(true);
                    setUsernameReadOnly(true);
                    showSuccessfulMessage('OTP sent successfully');
                })
                .catch((error) => {
                    props.hideLoader();
                    // console.error(`statusCode : ${error.code} & statusMessage : ${error.message} & apiMessage : ${error.apiMessage}`);
                    props.apiErrorModalHandleShow(error.code, error.message, error.apiMessage);
                });
        } else {
            showFailureMessage('Mobile number is invalid');
        }
    };

    const handleOTPVerification = (e) => {
        e.preventDefault();
        const mobileNo = getValues('user_name');
        const otp = getValues('otp');
        const mobileNoRegex = /^[6-9]\d{9}$/;
        if (mobileNoRegex.test(mobileNo)) {
            const myHeaders = new Headers();
            myHeaders.append('API-Key', defaultApiKey);
            myHeaders.append('Content-Type', 'application/json');

            const raw = JSON.stringify({'user_name': mobileNo});

            const requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow',
            };

            props.showLoader();
            fetch(verifyOtpUrl + otp + `?id=${currentMerchant}`, requestOptions)
                .then((response) => {
                    if (!response.ok) {
                        return response.json().then((responseBody) => {
                            throw new ApiError(response.status, response.statusText, responseBody.Status);
                        });
                    }
                    return response.json();
                })
                .then((result) => {
                    props.hideLoader();
                    setOTPVerified(true);
                    showSuccessfulMessage('OTP Verified Successfully');
                })
                .catch((error) => {
                    props.hideLoader();
                    console.error(`statusCode : ${error.code} & statusMessage : ${error.message} & apiMessage : ${error.apiMessage}`);
                    props.apiErrorModalHandleShow(error.code, error.message, error.apiMessage);
                });
        } else {
            alert('Mobile number is invalid');
        }
    };

    const handleCancel = () => {
        window.location = allRoutes.root;
    };

    return (
        <>
            <TopBar noMenu />
            <Container fluid="md" className="p-4 mb-5">
                <Breadcrumbs title="Sign Up" />
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Row>
                        <Form.Group as={Col} md="6" controlId="user_name">
                            <Form.Label>Username</Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    type="text"
                                    placeholder="Mobile Number"
                                    aria-describedby="inputGroupPrepend"
                                    name="user_name"
                                    ref={register}
                                    readOnly={usernameReadOnly}
                                />
                                <Form.Control.Feedback type="invalid" className="d-block">
                                    {errors.user_name && errors.user_name.message}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        {!otpVerified ?
                            <Form.Group as={Col} md="6" controlId="otp">
                                <Form.Label>OTP Verification</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type="text"
                                        placeholder="OTP"
                                        aria-describedby="basic-addon2"
                                        name="otp"
                                        ref={register}
                                    />
                                    <InputGroup.Append>
                                        <Button variant="outline-success" onClick={handleOTPVerification}>Verify</Button>
                                    </InputGroup.Append>
                                    <Form.Control.Feedback type="valid" className="d-block">
                                        {!sentOTP ?
                                            <p className="ponter-on-hover" onClick={handleOTPSend}>Send OTP</p> : ''
                                            // <p className="ponter-on-hover" onClick={handleOTPSend}>Resend OTP</p>
                                        }
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group> : ''
                        }
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="6" controlId="mdm_user">
                            <Form.Label>Your Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Your Name"
                                name="mdm_user"
                                ref={register}
                            />
                            <Form.Control.Feedback type="invalid" className="d-block">
                                {errors.mdm_user && errors.mdm_user.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="email_id">
                            <Form.Label>Email ID</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Email ID"
                                name="email_id"
                                ref={register}
                            />
                            <Form.Control.Feedback type="invalid" className="d-block">
                                {errors.email_id && errors.email_id.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="6" controlId="passwd">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                name="passwd"
                                ref={register}
                            />
                            <Form.Control.Feedback type="invalid" className="d-block">
                                {errors.passwd && errors.passwd.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="cnfpasswd">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Confirm Password"
                                name="cnfpasswd"
                                ref={register}
                                onPaste={preventPaste}
                            />
                            <Form.Control.Feedback type="invalid" className="d-block">
                                {errors.cnfpasswd && errors.cnfpasswd.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="6" controlId="remarks">
                            <Form.Label>Company Name</Form.Label>
                            <RHFInput
                                as={<Select options={
                                    [
                                        {value: currentMerchant, label: currentMerchant},
                                    ]
                                } />}
                                name="remarks"
                                register={register}
                                isClearable
                                setValue={(...selected) => {
                                    setValue(selected[0], selected[1], {shouldValidate: true});
                                }}
                            />
                            <Form.Control.Feedback type="invalid" className="d-block">
                                {errors.remarks && errors.remarks.value.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="location">
                            <Form.Label>Location</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Location"
                                name="location"
                                ref={register}
                            />
                            <Form.Control.Feedback type="invalid" className="d-block">
                                {errors.location && errors.location.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="6" controlId="pin_code">
                            <Form.Label>Pincode</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Pincode"
                                name="pin_code"
                                ref={register}
                            />
                            <Form.Control.Feedback type="invalid" className="d-block">
                                {errors.pin_code && errors.pin_code.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="6" controlId="secret_ques">
                            <Form.Label>Security Question</Form.Label>
                            <RHFInput
                                as={<Select options={securityQuestions} />}
                                name="secret_ques"
                                register={register}
                                isClearable
                                setValue={(...selected) => {
                                    setValue(selected[0], selected[1], {shouldValidate: true});
                                }}
                            />
                            <Form.Control.Feedback type="invalid" className="d-block">
                                {errors.secret_ques && errors.secret_ques.value.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="answr">
                            <Form.Label>Security Question Answer</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Security Question Answer"
                                name="answr"
                                ref={register}
                            />
                            <Form.Control.Feedback type="invalid" className="d-block">
                                {errors.answr && errors.answr.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="6" controlId="access_type">
                            <Form.Label>Access Type</Form.Label>
                            <RHFInput
                                as={<Select options={
                                    [
                                        // {value: 'Add', label: 'Add'},
                                        // {value: 'Admin', label: 'Admin'},
                                        // {value: 'Print', label: 'Print'},
                                        {value: 'View', label: 'View'},
                                    ]
                                } />}
                                name="access_type"
                                register={register}
                                isClearable
                                setValue={(...selected) => {
                                    setValue(selected[0], selected[1], {shouldValidate: true});
                                }}
                            />
                            <Form.Control.Feedback type="invalid" className="d-block">
                                {errors.access_type && errors.access_type.value.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="status_1">
                            <Form.Label>Status</Form.Label>
                            <RHFInput
                                as={<Select options={
                                    [
                                        {value: 'Active', label: 'Active'},
                                        {value: 'In-active', label: 'In-active'},
                                        {value: 'On Leave', label: 'On Leave'},
                                    ]
                                } />}
                                name="status_1"
                                register={register}
                                isClearable
                                setValue={(...selected) => {
                                    setValue(selected[0], selected[1], {shouldValidate: true});
                                }}
                            />
                            <Form.Control.Feedback type="invalid" className="d-block">
                                {errors.status_1 && errors.status_1.value.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="12" controlId="submit">
                            <Button
                                type="submit"
                                className="float-right ml-1"
                                disabled={!otpVerified ? true : undefined}
                            >Submit</Button>
                            <Button className="float-right" onClick={handleCancel}>Back</Button>
                        </Form.Group>
                    </Form.Row>
                </Form>
                <NotificationContainer />
            </Container>
            <Footer />
        </>
    );
};

Signup.propTypes = {
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    apiErrorModalHandleShow: PropTypes.func.isRequired,
};

export default Signup;
