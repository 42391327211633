import React from 'react';
import {Container} from 'react-bootstrap';
import authManager from '../utils/authManager';
import TopBar from '../components/topBar';
import Footer from '../components/footer';

class Logout extends React.Component {
    componentDidMount() {
        authManager.unsetUser();
    }

    render() {
        return (
            <>
                <TopBar noMenu />
                <Container fluid className="my-auto">
                    <div className="d-flex justify-content-center align-items-center" id="main">
                        <h1 className="mr-3 pr-3 align-top border-right inline-block align-content-center">Hurray!</h1>
                        <div className="inline-block align-middle">
                            <h2
                                className="font-weight-normal lead"
                                id="desc"
                            >You have successfully logged out. Thank You. Please close this page for security purpose.
                            </h2>
                        </div>
                    </div>
                </Container>
                <Footer />
            </>
        );
    }
}

export default Logout;
