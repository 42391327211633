import React, {useState, useEffect} from 'react';
import {Container, Tabs, Tab} from 'react-bootstrap';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import PropTypes from 'prop-types';
import Topbar from '../components/topBar';
import Footer from '../components/footer';
import ApiError from '../utils/apiError';
import authManager from '../utils/authManager';
import {getUserUrl, updateProfileUrl, changePasswordUrl, currentMerchant} from '../config';
import 'react-notifications/lib/notifications.css';
import ProfileManagement from '../components/profileManagement';
import ChangePassword from '../components/changePassword';

const Profile = (props) => {
    const [tabKey, setTabKey] = useState('profile');
    const [profileDetails, setProfileDetails] = useState({});

    const showSuccessfulMessage = (message) => {
        NotificationManager.success(message, 'Success');
    };

    const showFailureMessage = (message) => {
        NotificationManager.error(message, 'Error');
    };

    const getProfile = () => {
        props.showLoader();
        const myHeaders = new Headers();
        myHeaders.append('API-Key', authManager.getApiKey());
        myHeaders.append('Content-Type', 'application/json');

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        return fetch(getUserUrl, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((responseBody) => {
                        throw new ApiError(response.status, response.statusText, responseBody.Status);
                    });
                }
                return response.json();
            })
            .then((result) => {
                props.hideLoader();
                return Promise.resolve(result);
            })
            .catch((error) => {
                props.hideLoader();
                return Promise.reject(error);
            });
    };

    const updateProfile = (data) => {
        const myHeaders = new Headers();
        myHeaders.append('API-Key', authManager.getApiKey());
        myHeaders.append('Content-Type', 'application/json');

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(data),
            redirect: 'follow',
        };

        fetch(updateProfileUrl, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((responseBody) => {
                        throw new ApiError(response.status, response.statusText, responseBody.Status);
                    });
                }
                return response.json();
            })
            .then((result) => {
                showSuccessfulMessage('Profile Updated Successfully');
            })
            .catch((error) => {
                showFailureMessage(error.apiMessage);
            });
    };

    const changePasswordSave = (data) => {
        const myHeaders = new Headers();
        myHeaders.append('API-Key', authManager.getApiKey());
        myHeaders.append('Content-Type', 'application/json');

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(data),
            redirect: 'follow',
        };

        fetch(changePasswordUrl + `?id=${currentMerchant}`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((responseBody) => {
                        throw new ApiError(response.status, response.statusText, responseBody.Status);
                    });
                }
                return response.json();
            })
            .then((result) => {
                showSuccessfulMessage(result.Status);
            })
            .catch((error) => {
                showFailureMessage(error.apiMessage);
            });
    };

    useEffect(() => {
        getProfile()
            .then((result) => {
                setProfileDetails(result);
            })
            .catch((error) => {
                showFailureMessage(error.apiMessage);
            });
    }, []); // eslint-disable-line

    return (
        <>
            <Topbar />
            <Container fluid="md" className="shadow p-4 mt-3 mb-5 bg-white rounded">
                <Tabs
                    id="profile-tab"
                    activeKey={tabKey}
                    onSelect={(k) => setTabKey(k)}
                >
                    <Tab eventKey="profile" title="Profile">
                        <ProfileManagement
                            profile={profileDetails}
                            updateProfile={updateProfile}
                        />
                    </Tab>
                    <Tab eventKey="change-password" title="Change Password">
                        <ChangePassword
                            changePasswordSave={changePasswordSave}
                        />
                    </Tab>
                </Tabs>
                <NotificationContainer />
            </Container>
            <Footer />
        </>
    );
};

Profile.propTypes = {
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    // apiErrorModalHandleShow: PropTypes.func.isRequired,
};

export default Profile;
