import React from 'react';
import {Breadcrumb} from 'react-bootstrap';
import PropTypes from 'prop-types';

const Breadcrumbs = (props) => {
    return (
        <Breadcrumb>
            <Breadcrumb.Item active>{props.title}</Breadcrumb.Item>
        </Breadcrumb>
    );
};

Breadcrumbs.propTypes = {
    title: PropTypes.string.isRequired,
};

export default Breadcrumbs;
