import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {Form, Col, Button} from 'react-bootstrap';
import * as yup from 'yup';
import Select from 'react-select';
import {RHFInput} from 'react-hook-form-input';
import DatePicker from 'react-datepicker';
import {subDays, format} from 'date-fns';
import PropTypes from 'prop-types';
import authManager from '../../../utils/authManager';
import logManager from '../../../utils/logManager';
import {consumerAdd, consumerEdit} from '../../../config';
import ApiError from '../../../utils/apiError';
import 'react-datepicker/dist/react-datepicker.css';

const emptyStringToNull = (value, originalValue) => {
    if (typeof originalValue === 'string' && originalValue === '') {
        return null;
    }
    return value;
};

const schema = yup.object().shape({
    consumer_id: yup.string().required('Consumer ID is required'),
    consumer_name: yup.string().required('Consumer Name is required'),
    address_1: yup.string(),
    address_2: yup.string(),
    location: yup.string(),
    pin_code: yup.number().transform(emptyStringToNull).nullable(),
    contact_no: yup.number().transform(emptyStringToNull).nullable(),
    category: yup.object().shape({
        value: yup.string(),
        // label: yup.string(),
    }).nullable(),
    tariff_class: yup.string(),
    consumer_no: yup.string(),
    device_id: yup.object().shape({
        value: yup.string(),
        // label: yup.string(),
    }).nullable(),
    consumer_stat: yup.object().shape({
        value: yup.string(),
        // label: yup.string(),
    }).nullable(),
    mtr_conn_stat: yup.object().shape({
        value: yup.string(),
        // label: yup.string(),
    }).nullable(),
    connect_date: yup.date().transform(emptyStringToNull).nullable(),
    replmnt_date: yup.date().transform(emptyStringToNull).nullable(),
    replaced_reading: yup.number().transform(emptyStringToNull).nullable(),
    conntd_load: yup.number().transform(emptyStringToNull).nullable(),
    conntd_unt: yup.object().shape({
        value: yup.string(),
        // label: yup.string(),
    }).nullable(),
    ct_pt_oprtd: yup.object().shape({
        value: yup.string().oneOf(['Y', 'N']),
        // label: yup.string(),
    }).nullable(),
    e_ct_primary: yup.object().shape({
        value: yup.number().transform(emptyStringToNull).nullable(),
    }),
    e_ct_secondary: yup.object().shape({
        value: yup.number().transform(emptyStringToNull).nullable(),
    }),
    e_ct_ratio: yup.number().transform(emptyStringToNull).nullable(),
    e_pt_primary: yup.object().shape({
        value: yup.number().transform(emptyStringToNull).nullable(),
    }),
    e_pt_secondary: yup.object().shape({
        value: yup.number().transform(emptyStringToNull).nullable(),
    }),
    e_pt_ratio: yup.number().transform(emptyStringToNull).nullable(),
    e_applicabl_mf: yup.number().transform(emptyStringToNull).nullable(),
    x_cordinate: yup.number().transform(emptyStringToNull).nullable(),
    y_cordinate: yup.number().transform(emptyStringToNull).nullable(),
});

const ConsumerForm = (props) => {
    const {register, handleSubmit, errors, setValue, getValues, reset} = useForm({
        validationSchema: schema,
        defaultValues: {
            connect_date: props.consumer.connect_date ? new Date(props.consumer.connect_date) : null,
            replmnt_date: props.consumer.replmnt_date ? new Date(props.consumer.connect_date) : null,
        },
    });

    const [connectionDate, setConnectionDate] = useState(props.consumer.connect_date ? new Date(props.consumer.connect_date) : null);
    const [replacementDate, setReplacementDate] = useState(props.consumer.replmnt_date ? new Date(props.consumer.replmnt_date) : null);

    const onSubmit = async (data) => {
        logManager.info(data);
        const tempData = {};
        for (const key in data) {
            if (data[key] instanceof Date) {
                tempData[key] = format(data[key], 'yyyy-MM-dd');
            } else if (typeof data[key] === 'object' && data[key] !== null) {
                if (data[key].value !== undefined && data[key].value !== null) {
                    tempData[key] = data[key].value;
                } else {
                    tempData[key] = '';
                }
            } else {
                if (data[key] === null) {
                    tempData[key] = '';
                } else {
                    tempData[key] = data[key];
                }
            }
        }
        logManager.info(tempData);
        await saveConsumer(tempData, props.isAdd);
    };

    const saveConsumer = (data, isAdd) => {
        props.showLoader();
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('API-Key', authManager.getApiKey());

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(data),
        };

        const url = isAdd ? consumerAdd : consumerEdit;

        return fetch(url, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((responseBody) => {
                        throw new ApiError(response.status, response.statusText, responseBody.Status);
                    });
                }
                return response.json();
            })
            .then((result) => {
                logManager.info(result);
                props.hideLoader();
                props.showSuccessfulMessage(result.Status);
            })
            .catch((error) => {
                props.hideLoader();
                logManager.error(`statusCode : ${error.code} & statusMessage : ${error.message} & apiMessage : ${error.apiMessage}`);
                props.apiErrorModalHandleShow(error.code, error.message, error.apiMessage);
            });
    };

    const handleCancel = async () => {
        reset();
        props.handleCancel();
    };

    const getConsumerCategory = () => {
        return props.listOfValues.consumer_category.map((element) => {
            return {value: element.status, label: element.status};
        });
    };

    const getDevices = () => {
        return props.listOfDevices.map((element) => {
            return {value: element.device_id, label: element.device_id};
        });
    };

    const getConsumerStatus = () => {
        return props.listOfValues.consumer_status.map((element) => {
            return {value: element.status, label: element.status};
        });
    };

    const getMeterConnectionStatus = () => {
        const results = props.listOfValues.status_master.reduce(function(filtered, element) {
            if (element.type === 'Meter Connection') {
                filtered.push({value: element.status, label: element.status});
            }
            return filtered;
        }, []);

        return results;
    };

    const getConnectedUnit = () => {
        return props.listOfValues.cd_unit.map((element) => {
            return {value: element.unit, label: element.unit};
        });
    };

    const getCTPTOperated = () => {
        return [
            {value: 'Y', label: 'Yes'},
            {value: 'N', label: 'No'},
        ];
    };

    const getCT = (type) => {
        const results = props.listOfValues.ctr_master.reduce(function(filtered, element) {
            if (element.ct_typ === type) {
                filtered.push({value: element.ct_rating, label: element.ct_rating});
            }
            return filtered;
        }, []);

        return results;
    };

    const getPT = (type) => {
        const results = props.listOfValues.ptr_master.reduce(function(filtered, element) {
            if (element.pt_typ === type) {
                filtered.push({value: element.pt_rating, label: element.pt_rating});
            }
            return filtered;
        }, []);

        return results;
    };

    const handleCTPTOperated = (selectedOption) => {
        if (selectedOption) {
            if (selectedOption.value === 'N') {
                setValue('e_ct_primary', {value: '1', label: '1'}, {shouldValidate: true});
                setValue('e_ct_secondary', {value: '1', label: '1'}, {shouldValidate: true});
                setValue('e_ct_ratio', '1', {shouldValidate: true});
                setValue('e_pt_primary', {value: '1', label: '1'}, {shouldValidate: true});
                setValue('e_pt_secondary', {value: '1', label: '1'}, {shouldValidate: true});
                setValue('e_pt_ratio', '1', {shouldValidate: true});
                setValue('e_applicabl_mf', '1', {shouldValidate: true});
            }
        }
    };

    const setApplicableMF = () => {
        const ptRatio = getValues('e_pt_ratio');
        const ctRatio = getValues('e_ct_ratio');
        if (ptRatio && ctRatio) {
            setValue('e_applicabl_mf', ptRatio * ctRatio, {shouldValidate: true});
        }
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md="4" controlId="consumer_id">
                    <Form.Label>Consumer ID</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Consumer ID"
                        name="consumer_id"
                        ref={register}
                        defaultValue={props.consumer.consumer_id}
                        readOnly={props.isAdd ? undefined : true}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.consumer_id && errors.consumer_id.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="consumer_name">
                    <Form.Label>Consumer Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Consumer Name"
                        name="consumer_name"
                        ref={register}
                        defaultValue={props.consumer.consumer_name}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.consumer_name && errors.consumer_name.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="merchant_id">
                    <Form.Label>Address1</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Address1"
                        name="address_1"
                        ref={register}
                        defaultValue={props.consumer.address_1}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.address_1 && errors.address_1.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md="4" controlId="address_2">
                    <Form.Label>Address2</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Address2"
                        name="address_2"
                        ref={register}
                        defaultValue={props.consumer.address_2}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.address_2 && errors.address_2.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="location">
                    <Form.Label>Location</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Location"
                        name="location"
                        ref={register}
                        defaultValue={props.consumer.location}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.location && errors.location.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="pin_code">
                    <Form.Label>Pin Code</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Pin Code"
                        name="pin_code"
                        ref={register}
                        defaultValue={props.consumer.pin_code}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.pin_code && errors.pin_code.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md="4" controlId="contact_no">
                    <Form.Label>Contact No</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Contact No"
                        name="contact_no"
                        ref={register}
                        defaultValue={props.consumer.contact_no}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.contact_no && errors.contact_no.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="category">
                    <Form.Label>Category</Form.Label>
                    <RHFInput
                        as={<Select options={getConsumerCategory()} />}
                        name="category"
                        register={register}
                        isClearable
                        defaultValue={{
                            label: props.consumer.category,
                            value: props.consumer.category,
                        }}
                        setValue={(...selected) => {
                            setValue(selected[0], selected[1], {shouldValidate: true});
                        }}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.category && errors.category.value && errors.category.value.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="tariff_class">
                    <Form.Label>Tariff Class</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Tariff Class"
                        name="tariff_class"
                        ref={register}
                        defaultValue={props.consumer.tariff_class}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.tariff_class && errors.tariff_class.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md="4" controlId="consumer_no">
                    <Form.Label>Consumer No</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Consumer No"
                        name="consumer_no"
                        ref={register}
                        defaultValue={props.consumer.consumer_no}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.consumer_no && errors.consumer_no.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="device_id">
                    <Form.Label>Meter No</Form.Label>
                    <RHFInput
                        as={<Select options={getDevices()} />}
                        name="device_id"
                        register={register}
                        isClearable
                        defaultValue={{
                            label: props.consumer.device_id,
                            value: props.consumer.device_id,
                        }}
                        setValue={(...selected) => {
                            setValue(selected[0], selected[1], {shouldValidate: true});
                        }}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.device_id && errors.device_id.value && errors.device_id.value.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="consumer_stat">
                    <Form.Label>Consumer Status</Form.Label>
                    <RHFInput
                        as={<Select options={getConsumerStatus()} />}
                        name="consumer_stat"
                        register={register}
                        isClearable
                        defaultValue={{
                            label: props.consumer.consumer_stat,
                            value: props.consumer.consumer_stat,
                        }}
                        setValue={(...selected) => {
                            setValue(selected[0], selected[1], {shouldValidate: true});
                        }}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.consumer_stat && errors.consumer_stat.value && errors.consumer_stat.value.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md="4" controlId="mtr_conn_stat">
                    <Form.Label>Meter Connection Status</Form.Label>
                    <RHFInput
                        as={<Select options={getMeterConnectionStatus()} />}
                        name="mtr_conn_stat"
                        register={register}
                        isClearable
                        defaultValue={{
                            label: props.consumer.mtr_conn_stat,
                            value: props.consumer.mtr_conn_stat,
                        }}
                        setValue={(...selected) => {
                            setValue(selected[0], selected[1], {shouldValidate: true});
                        }}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.mtr_conn_stat && errors.mtr_conn_stat.value && errors.mtr_conn_stat.value.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="connect_date">
                    <Form.Label>Connection Date</Form.Label><br></br>
                    <RHFInput
                        as={<DatePicker
                            className="form-control"
                            isClearable
                            selected={connectionDate}
                            dateFormat="yyyy-MM-dd"
                            maxDate={subDays(new Date(), 1)}
                            showDisabledMonthNavigation
                        />}
                        name="connect_date"
                        register={register}
                        setValue={(...selected) => {
                            setConnectionDate(selected[1]);
                            setValue(selected[0], selected[1], {shouldValidate: true});
                        }}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.connect_date && errors.connect_date.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="replmnt_date">
                    <Form.Label>Replacement Date</Form.Label><br></br>
                    <RHFInput
                        as={<DatePicker
                            className="form-control"
                            isClearable
                            selected={replacementDate}
                            dateFormat="yyyy-MM-dd"
                            maxDate={subDays(new Date(), 1)}
                            showDisabledMonthNavigation
                        />}
                        name="replmnt_date"
                        register={register}
                        setValue={(...selected) => {
                            setReplacementDate(selected[1]);
                            setValue(selected[0], selected[1], {shouldValidate: true});
                        }}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.replmnt_date && errors.replmnt_date.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md="4" controlId="replaced_reading">
                    <Form.Label>Replaced Reading</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Replaced Reading"
                        name="replaced_reading"
                        ref={register}
                        defaultValue={props.consumer.replaced_reading}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.replaced_reading && errors.replaced_reading.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="conntd_load">
                    <Form.Label>Connected Load</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Connected Load"
                        name="conntd_load"
                        ref={register}
                        defaultValue={props.consumer.conntd_load || 1.0}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.conntd_load && errors.conntd_load.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="conntd_unt">
                    <Form.Label>Connected Unit</Form.Label>
                    <RHFInput
                        as={<Select options={getConnectedUnit()} />}
                        name="conntd_unt"
                        register={register}
                        isClearable
                        defaultValue={{
                            label: props.consumer.conntd_unt,
                            value: props.consumer.conntd_unt,
                        }}
                        setValue={(...selected) => {
                            setValue(selected[0], selected[1], {shouldValidate: true});
                        }}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.conntd_unt && errors.conntd_unt.value && errors.conntd_unt.value.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md="4" controlId="ct_pt_oprtd">
                    <Form.Label>CT PT Operated</Form.Label>
                    <RHFInput
                        as={<Select options={getCTPTOperated()} />}
                        name="ct_pt_oprtd"
                        register={register}
                        isClearable
                        defaultValue={{
                            label: props.consumer.ct_pt_oprtd,
                            value: props.consumer.ct_pt_oprtd,
                        }}
                        setValue={(...selected) => {
                            setValue(selected[0], selected[1], {shouldValidate: true});
                            handleCTPTOperated(selected[1]);
                        }}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.ct_pt_oprtd && errors.ct_pt_oprtd.value && errors.ct_pt_oprtd.value.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="e_ct_primary">
                    <Form.Label>CT Primary</Form.Label>
                    <RHFInput
                        as={<Select options={getCT('Primary')} />}
                        name="e_ct_primary"
                        register={register}
                        isClearable
                        defaultValue={{
                            label: props.consumer.e_ct_primary,
                            value: props.consumer.e_ct_primary,
                        }}
                        setValue={(...selected) => {
                            setValue(selected[0], selected[1], {shouldValidate: true});
                        }}
                        onChange={(selected) => {
                            const ctSecondary = getValues('e_ct_secondary');
                            if (ctSecondary.value) {
                                setValue('e_ct_ratio', parseFloat(selected.value) / parseFloat(ctSecondary.value), {shouldValidate: true});
                                setApplicableMF();
                            }
                        }}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.e_ct_primary && errors.e_ct_primary.value && errors.e_ct_primary.value.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="e_ct_secondary">
                    <Form.Label>CT Secondary</Form.Label>
                    <RHFInput
                        as={<Select options={getCT('Secondary')} />}
                        name="e_ct_secondary"
                        register={register}
                        isClearable
                        defaultValue={{
                            label: props.consumer.e_ct_secondary,
                            value: props.consumer.e_ct_secondary,
                        }}
                        setValue={(...selected) => {
                            setValue(selected[0], selected[1], {shouldValidate: true});
                        }}
                        onChange={(selected) => {
                            const ctPrimary = getValues('e_ct_primary');
                            if (ctPrimary.value) {
                                setValue('e_ct_ratio', parseFloat(ctPrimary.value) / parseFloat(selected.value), {shouldValidate: true});
                                setApplicableMF();
                            }
                        }}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.e_ct_secondary && errors.e_ct_secondary.value && errors.e_ct_secondary.value.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md="4" controlId="e_ct_ratio">
                    <Form.Label>CT Ratio</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="CT Ratio"
                        name="e_ct_ratio"
                        ref={register}
                        defaultValue={props.consumer.e_ct_ratio}
                        readOnly
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.e_ct_ratio && errors.e_ct_ratio.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="e_pt_primary">
                    <Form.Label>PT Primary</Form.Label>
                    <RHFInput
                        as={<Select options={getPT('Primary')} />}
                        name="e_pt_primary"
                        register={register}
                        isClearable
                        defaultValue={{
                            label: props.consumer.e_pt_primary,
                            value: props.consumer.e_pt_primary,
                        }}
                        setValue={(...selected) => {
                            setValue(selected[0], selected[1], {shouldValidate: true});
                        }}
                        onChange={(selected) => {
                            const ptSecondary = getValues('e_pt_secondary');
                            if (ptSecondary.value) {
                                setValue('e_pt_ratio', parseFloat(selected.value) / parseFloat(ptSecondary.value), {shouldValidate: true});
                                setApplicableMF();
                            }
                        }}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.e_pt_primary && errors.e_pt_primary.value && errors.e_pt_primary.value.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="e_pt_secondary">
                    <Form.Label>PT Secondary</Form.Label>
                    <RHFInput
                        as={<Select options={getPT('Secondary')} />}
                        name="e_pt_secondary"
                        register={register}
                        isClearable
                        defaultValue={{
                            label: props.consumer.e_pt_secondary,
                            value: props.consumer.e_pt_secondary,
                        }}
                        setValue={(...selected) => {
                            setValue(selected[0], selected[1], {shouldValidate: true});
                        }}
                        onChange={(selected) => {
                            const ptPrimary = getValues('e_pt_primary');
                            if (ptPrimary.value) {
                                const ptRatio = parseFloat(ptPrimary.value) / parseFloat(selected.value);
                                setValue('e_pt_ratio', ptRatio, {shouldValidate: true});
                                setApplicableMF();
                            }
                        }}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.e_pt_secondary && errors.e_pt_secondary.value && errors.e_pt_secondary.value.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md="4" controlId="e_pt_ratio">
                    <Form.Label>PT Ratio</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="PT Ratio"
                        name="e_pt_ratio"
                        ref={register}
                        defaultValue={props.consumer.e_pt_ratio}
                        readOnly
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.e_pt_ratio && errors.e_pt_ratio.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="e_applicabl_mf">
                    <Form.Label>Applicable MF</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Applicable MF"
                        name="e_applicabl_mf"
                        ref={register}
                        defaultValue={props.consumer.e_applicabl_mf}
                        readOnly
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.e_applicabl_mf && errors.e_applicabl_mf.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="x_cordinate">
                    <Form.Label>X Coordinate</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="X Coordinate"
                        name="x_cordinate"
                        ref={register}
                        defaultValue={props.consumer.x_cordinate}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.x_cordinate && errors.x_cordinate.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <Form.Row className="">
                <Form.Group as={Col} md="4" controlId="y_cordinate">
                    <Form.Label>Y Coordinate</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Y Coordinate"
                        name="y_cordinate"
                        ref={register}
                        defaultValue={props.consumer.y_cordinate}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.y_cordinate && errors.y_cordinate.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <Form.Row className="justify-content-md-center mb-2">
                <Form.Group as={Col} md="12">
                    <Button type="submit" className="float-right ml-1">Submit</Button>
                    <Button className="float-right" onClick={handleCancel}>Cancel</Button>
                </Form.Group>
            </Form.Row>
        </Form>
    );
};

ConsumerForm.propTypes = {
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    showSuccessfulMessage: PropTypes.func.isRequired,
    apiErrorModalHandleShow: PropTypes.func.isRequired,
    isAdd: PropTypes.bool.isRequired,
    consumer: PropTypes.object.isRequired,
    listOfValues: PropTypes.object.isRequired,
    listOfDevices: PropTypes.array.isRequired,
};

export default ConsumerForm;
