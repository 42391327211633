import React from "react";
import TopBar from "../../components/topBar";
import Footer from "../../components/footer";
import { Card, Col, Container, Row } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import { useState } from "react";
import { landingPageUrl } from "../../config";
import ApiFetch from "../../utils/apiFetch";
import { useEffect } from "react";
import authManager from "../../utils/authManager";

export default function HesStatus(props) {
    const [state,setState]= useState({
        data:null,
        
    })

const fetchData=( )=>{
   
    props.showLoader();
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("API-Key", authManager.getApiKey());

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    let urlString= landingPageUrl;     
    return ApiFetch(props, urlString, requestOptions);
}
useEffect(()=>{
    fetchData()
    .then((res)=>{
        setState({...state, data:res})
    })
    .catch((error)=>{
        setState({...state, data:null})
    })
    
   
},[])
 

const getHESData=()=>{
return state.data===null ? "" : state.data.hes_data; 
}


const getHESColumn=()=>{
    const columns = [
        {
            dataField: 'recd_date',
            text: 'Record Date',
        },
        {
            dataField: 'recd_from_hes',
            text: 'Record from HES',
        },
        {
            dataField: 'err_in_hes',
            text: 'Error In HES',
        },
        {
            dataField: 'posted_data',
            text: 'Posted Data',
        },
        {
            dataField: 'INST',
            text: 'INST',
        },
        {
            dataField: 'BILL',
            text: 'BILL',
        },
        {
            dataField: 'MIDN',
            text: 'MIDN',
        },
        {
            dataField: 'LOADS',
            text: 'LOADS',
        },
        {
            dataField: 'EVNT',
            text: 'EVNT',
        },
    ];
    return columns;
}

return (
    <>
      <TopBar />
      <Container fluid className="p-4 bg-white">
        <Row>
          <Col md="12">
            <Card className="mb-2">
              <Card.Header>
                HES Status
                <CSVLink
                  data={
                    state.data===null ? '' : state.data.hes_data
                  }
                  filename={"hes_status.csv"}
                  className="btn btn-primary btn-sm float-right"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faDownload} />
                </CSVLink>
              </Card.Header>
              <Card.Body style={{ overflowX: "scroll" }}>
                {state.data!== null ? (
                  <BootstrapTable
                    keyField="recd_date"
                    data={getHESData()}
                    columns={getHESColumn()}
                    bootstrap4
                    striped
                    hover
                    condensed
                    classes="bootstrap-striped-table2 font10"
                  />
                ) : (
                  ""
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
