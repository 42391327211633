import React, {useState} from 'react';
import {Modal, Button, Form, Col} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {validateMerchant} from '../utils/commonFunctions';

const MerchantInput = (props) => {
    const [merchantInput, setMerchantInput] = useState('');
    const [errors, setErrors] = useState({});

    const handleSubmit = () => {
        const merchantValidation = validateMerchant(merchantInput);

        if (merchantValidation === 'noMerchant') {
            setErrors({...errors, merchantInput: 'Cannot be blank'});
        } else if (merchantValidation === 'invalidMerchant') {
            setErrors({...errors, merchantInput: 'Given ID is invalid'});
        } else if (merchantValidation === 'validMerchant') {
            window.removeEventListener('beforeunload', props.handleUnload);
            window.location.href = `/${merchantInput}/`;
        }
    };

    return (
        <Modal show={props.show} animation={false}>
            <Modal.Header>
                <Modal.Title>Merchant ID Input Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group as={Col} md="12" controlId="merchantInput">
                    <Form.Label>Your Organization Code</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Give Your Organization Code"
                        name="merchantInput"
                        onChange={(e) => setMerchantInput(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors && errors.merchantInput}
                    </Form.Control.Feedback>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleSubmit}>
            Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

MerchantInput.propTypes = {
    show: PropTypes.bool.isRequired,
    handleUnload: PropTypes.func.isRequired,
};

export default MerchantInput;
