import React from 'react';
import {Container} from 'react-bootstrap';
import TopBar from '../components/topBar';
import Footer from '../components/footer';

class NotFound404 extends React.Component {
    render() {
        return (
            <>
                <TopBar noMenu />
                <Container fluid className="my-auto">
                    <div className="d-flex justify-content-center align-items-center" id="main">
                        <h1 className="mr-3 pr-3 align-top border-right inline-block align-content-center">404</h1>
                        <div className="inline-block align-middle">
                            <h2 className="font-weight-normal lead" id="desc">The page you requested was not found.</h2>
                        </div>
                    </div>
                </Container>
                <Footer />
            </>
        );
    }
}

export default NotFound404;
